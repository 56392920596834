import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SelectCategorie from "../components/SelectCategorie";
import SelectStato from "../components/SelectStato";
import { loading_login } from "../store/actions/login";
import Spinner from "../components/Spinner";
import Resizer from "react-image-file-resizer";

import { salva_prodotto, modifica_prodotto } from "../store/actions/ordini";
import "../style/Piatti.css";
import Formule from "../components/Formule";

const Piatti = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categorieall = useSelector((state) => state.ordini.categorieall);
  const loading2 = useSelector((state) => state.login.loading);
  const prodotto = useSelector((state) => state.ordini.prodotto);
  const formule = useSelector((state) => state.ordini.formule);

  const [railCards, setRailCards] = useState([]);
  const { id } = useParams();
  console.log("Prodotto da modificare", prodotto);
  const handleRailCardCLick = () => {
    let id = Math.floor(Math.random() * 1000000);
    setRailCards([
      ...railCards,
      {
        value: "",
        fieldName: `railcard-${railCards.length + 1}`,
        count: railCards.length + 1,
        id,
        dis: "0",
      },
    ]);
  };

  let [formData, setFormData] = useState(
    prodotto.length != 0
      ? {
          id_prodotto: prodotto[0].prodottis[0].id_prodotto,
          nome: prodotto[0].prodottis[0].nome,
          ingredienti_it: prodotto[0].prodottis[0].ingredienti_it,
          ingredienti_en: prodotto[0].prodottis[0].ingredienti_en,
          image: "",
          id_categoria: prodotto[0].id_categoria,
          prezzo: prodotto[0].prodottis[0].prezzo,
          prezzo_offerte: prodotto[0].prodottis[0].prezzo_offerte,
          stato: prodotto[0].prodottis[0].stato,
          categorie_scelte: [],
        }
      : {
          id_prodotto: "",
          nome: "",
          ingredienti_it: "",
          ingredienti_en: "",
          image: "",
          id_categoria: "",
          prezzo: "",
          prezzo_offerte: 0,
          stato: "",
          categorie_scelte: [],
        }
  );

  const stato = [
    { id: 1, nome: "attivo", valore: 1 },
    { id: 2, nome: "disattivo", valore: 2 },
  ];

  useEffect(() => {
    if (prodotto.length != 0) {
      var updatedList = [];
      setRailCards(
        prodotto[0].prodottis[0].rel_prod_formules &&
          prodotto[0].prodottis[0].rel_prod_formules.map((comment) => ({
            value: comment.formule.id_formula,
            nome: comment.formule.nome,
            fieldName: `railcard-${railCards.length + 1}`,
            count: railCards.length + 1,
            id: comment.formule.id_formula,
            dis: "2",
          }))
      );

      prodotto[0].prodottis[0].rel_prod_formules.forEach((comment) => {
        updatedList.push(comment.formule);
      });

      setFormData({ ...formData, categorie_scelte: updatedList });
    }
  }, [prodotto]);

  const salvaProdotto = async () => {
    await dispatch(loading_login(true));
    if (await dispatch(salva_prodotto(formData))) navigate("/portate");
    await dispatch(loading_login(false));
  };
  const modificaProdotto = async () => {
    await dispatch(loading_login(true));
    if (await dispatch(modifica_prodotto(formData))) navigate("/portate");
    await dispatch(loading_login(false));
  };
  const fileChangedHandler = async (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          300,
          300,
          "JPEG",
          50,
          0,
          (uri) => {
            setFormData({ ...formData, image: uri });
          },

          "base64",
          200,
          200
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="page">
      <div className="container-piatto margin50">
        {formData.length == 0 || formData == null || loading2 ? (
          <Spinner />
        ) : (
          <>
            <div className="spazio_input">
              <input
                id="first-name"
                type="text"
                className="campo_input"
              placeholder="Inserisci il nome del piatto"

                onChange={(e) =>
                  setFormData({ ...formData, nome: e.target.value })
                }
                value={formData.nome}
              />
              <span className="campo_label" aria-hidden="true">
                <span className="field__label">None del Piatto</span>
              </span>
            </div>
            <div className="spazio_input">
              <textarea
                id="first-name"
                type="text"
                className="campo_input"
                onChange={(e) =>
                  setFormData({ ...formData, ingredienti_it: e.target.value })
                }
                value={formData.ingredienti_it}
              />
              <span className="campo_label" aria-hidden="true">
                <span className="field__label">Ingredienti (ita)</span>
              </span>
            </div>
            <div className="spazio_input">
              <textarea
                id="first-name"
                type="text"
                className="campo_input"
                onChange={(e) =>
                  setFormData({ ...formData, ingredienti_en: e.target.value })
                }
                value={formData.ingredienti_en}
              />
              <span className="campo_label" aria-hidden="true">
                <span className="field__label">Ingredienti (en)</span>
              </span>
            </div>
            <div className="spazio_input">
              <input
                id="last-name"
                type="file"
                className="campo_input"
                placeholder="Inserisci il numero dei commensali"
                onChange={fileChangedHandler}
              />
            </div>
            <div className="container-ruoli">
              <SelectCategorie
                formData={formData}
                setFormData={setFormData}
                categorieall={categorieall}
              />
            </div>
            <div className="container-ruoli">
              <SelectStato
                formData={formData}
                setFormData={setFormData}
                stato={stato}
              />
            </div>
            <div className="spazio_input">
              <input
                id="last-name"
                type="number"
                className="campo_input"
                placeholder="Es. 4.5"
                onChange={(e) =>
                  setFormData({ ...formData, prezzo: e.target.value })
                }
                value={formData.prezzo}
              />
              <span className="campo_label" aria-hidden="true">
                <span className="field__label">Prezzo (€)</span>
              </span>
            </div>
            <div className="spazio_input">
              <input
                id="last-name"
                type="number"
                className="campo_input"
                placeholder="Es. 2.5"
                onChange={(e) =>
                  setFormData({ ...formData, prezzo_offerte: e.target.value })
                }
                value={formData.prezzo_offerte}
              />
              <span className="campo_label" aria-hidden="true">
                <span className="field__label">Prezzo Offerta (€)</span>
              </span>
            </div>
            <div className="container-categorie">
              <h3>Associa il prodotto ad una o piu Formule</h3>
              <Formule
                portate={formule}
                formData={formData}
                setFormData={setFormData}
                railCards={railCards}
                setRailCards={setRailCards}
                handleRailCardCLick={handleRailCardCLick}
              />
              <div className="contenittore_bottoni">
                {id == 0 ? (
                  <a
                    href="#fakeLink"
                    onClick={() => salvaProdotto(formData)}
                    className="btn purple"
                  >
                    Inserisci
                  </a>
                ) : (
                  <a
                    href="#fakeLink"
                    onClick={() => modificaProdotto(formData)}
                    className="btn purple"
                  >
                    Modifica
                  </a>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Piatti;
