import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { node } from "../../Axios";
import { loading_login, logout } from "../../store/actions/login";
import {
  get_ordini_aperti_sala,
  get_ordini_chiusi_sala,
  send_ordine_success,
  set_coperti,
  set_ordine,
  set_storico_ordine,
  set_tavolo,
  set_totale_generale,
} from "../../store/actions/ordini";
import { getSession } from "../../utils/session";
import Spinner from "../Spinner";
import Table from "../TableOrdiniChiusi";



const SecondTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ordini = useSelector((state) => state.ordini.ordini);
  const loading = useSelector((state) => state.ordini.loading);
  const loading2 = useSelector((state) => state.login.loading);
  const id_ruolo = useSelector((state) => state.login.id_ruolo);
  useEffect(() => {
    async function fetchordini() {
      await dispatch(loading_login(true));
      await dispatch(get_ordini_chiusi_sala());
      await dispatch(send_ordine_success());

      await dispatch(loading_login(false));
    }

    fetchordini();
  }, []);
  const storico = async (id_ordine, coperti, tot_generale, tavolo) => {
    await dispatch(set_tavolo(tavolo));
    await dispatch(set_ordine(id_ordine));
   // await set_storico_ordine(0);
    await dispatch(set_totale_generale(tot_generale));
    await dispatch(set_coperti(coperti));
    navigate(`../storicoclose/`);
  };

  return (
    <div className="content-tab">
      <div className="titolo-tab">Ordini Chiusi!</div>
      {loading || loading2 ? (
        <Spinner />
      ) : ordini != 0 ? (
        <div className="contenuto-ordini">
          <Table
            ordini={ordini}
            storico={storico}

          
            ruolo={id_ruolo}
          />
        </div>
      ) : (
        "Non sono ancora presenti ordini chiusi"
      )}
    </div> 
  );
};
export default SecondTab;
