import { createStore, applyMiddleware ,compose} from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import { createLogger } from 'redux-logger';

import allReducers from './store/reducers';

var middlewares = [];
middlewares.push(thunk);
middlewares.push(promise);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * If project is in production environment,
 * it won't show logger middleware.
 */
if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger({
    collapsed: true
  });
  
  middlewares.push(logger);
}else {
  // Sovrascrivi la funzione console.log in modo che non faccia nulla in produzione
  console.log = function () {};
}
const store = createStore(allReducers, composeEnhancers(applyMiddleware(...middlewares)));

export default store;