import React from "react";
import "../style/Table.css";
import MyButton from "./MyButton";

const TableOrdiniChiusi = ({
  ordini,

  storico,
}) => {
  let date_format;
  return (
    <table>
      <thead>
        <th>N° Ordine</th>
        <th>Data</th>
        <th>Tavolo</th>
        <th>N° Persone</th>
        <th>N° Bambini</th>
        <th>Totale(€)</th>
        <th>Action</th>
      </thead>

      {ordini.map((ordine, index) => {
        date_format = new Date(ordine.createdAt);
        return (
          <tbody>
            <tr key={index}>
              <td>
                <label>N° Ordine</label>
                <span>{ordine.id_ordine}</span>
              </td>
              <td>
                <label>Data</label>
                <span>{date_format.toLocaleString()}</span>
              </td>
              <td>
                <label>Tavolo</label>
                <span>{ordine.ordini_tavolos[0].numero_tavolo}</span>
              </td>
              <td>
                <label>N° Persone</label>
                <span>{ordine.ordini_tavolos[0].nume_persone}</span>
              </td>
              <td>
                <label>N° Bambini</label>
                <span>{ordine.ordini_tavolos[0].nume_bambini}</span>
              </td>
              <td>
                <label>Totale(€)</label>
                <span>{ordine.totale} €</span>
              </td>

              <td>
              <div className="contenuto">
              <MyButton
                   onClick={() =>
                    storico(
                      ordine.id_ordine,
                      ordine.ordini_tavolos[0].nume_persone,
                      ordine.totale,
                      ordine.ordini_tavolos[0].numero_tavolo
                    )
                  }
                  className="large-icon mybutton"
                  tooltip={"Storico"}
                  name={"receipt_long"}
                />
                </div>
                {/* {
                  <a
                    href="#fakeLink"
                    onClick={() =>
                      storico(
                        ordine.id_ordine,
                        ordine.ordini_tavolos[0].nume_persone,
                        ordine.totale,
                        ordine.ordini_tavolos[0].numero_tavolo
                      )
                    }
                    className="btn purple"
                  >
                    Storico
                  </a>
                } */}
              </td>
            </tr>
          </tbody>
        );
      })}
    </table>
  );
};

export default TableOrdiniChiusi;
