import React, { useState } from "react";
import "../style/Modal.css";

const Modal = (props) => {
  console.log("Props in model", props);
  let prezzo =
    props.datiModal.card.prezzo_offerte == 0.0 &&
    props.datiModal.card.prezzo == 0.0
      ? Number(props.datiModal.card.prezzo_visual)
      : "";
  return (
    <>
      <div className="modal-container">
        <div className="container-infomodal">
          <div className="menu-close" onClick={() => props.toggleModal()}>
            <span className="material-symbols-rounded">close</span>
          </div>
          <div className="nome-prodotto">{props.datiModal.card.nome}</div>
          <div className="dati-prodotto">
            <div className="img-prodotto">
              <img src={props.datiModal.card.path} />
            </div>
            <div className="ingredienti-modal">
              {props.datiModal.card.ingredienti_it}
            </div>
            <div className="ingredienti-modal eng">
              {props.datiModal.card.ingredienti_en}
            </div>
            {prezzo != "" ? (
              <div className="ingredienti-modal eng">Euro: {prezzo}</div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default Modal;
