import React from "react";
import "../style/Table.css";

const TableOrdiniChiusi = ({
  ordini,

  storico,
}) => {
  let date_format;
  return (
    <table>
      <thead>
        <th>N° Ordine</th>
        <th>Data</th>
        <th>Nome</th>
        <th>Pin</th>
        <th>Totale(€)</th>
        <th>Action</th>
      </thead>

      <tbody>
        {ordini.map((ordine, index) => {
          date_format = new Date(ordine.createdAt);
          return (
            <tr key={index}>
              <td>
                <label>N° Ordine</label>
                <span>{ordine.id_ordine}</span>
              </td>
              <td>
                <label>Data</label>
                <span>{date_format.toLocaleString()}</span>
              </td>
              <td>
                <label>Nome</label>
                <span>
                  {ordine.ordini_ombrellonis[0].persone.nome}{" "}
                  {ordine.ordini_ombrellonis[0].persone.cognome}
                </span>
              </td>
              <td>
                <label>Pin</label>
                <span>{ordine.ordini_ombrellonis[0].pin}</span>
              </td>
              <td>
                <label>Totale(€)</label>
                <span>{ordine.totale.toFixed(2)}</span>
              </td>

              <td>
                {
                  <a
                    href="#fakeLink"
                    onClick={() =>
                      storico(
                        ordine.id_ordine,

                        ordine.totale
                      )
                    }
                    className="btn purple"
                  >
                    Storico
                  </a>
                }
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableOrdiniChiusi;
