import React from "react";
import "../style/Table.css";
import MyButton from "./MyButton";

const TableOmbrelloni = ({ ombrelloni, modifica, stampa }) => {
  return (
    <table>
      <thead>
        <th>Fila</th>
        <th>Posizione</th>
        <th>Numero</th>
        {/* <th>Qrurl</th> */}

        <th>Action</th>
      </thead>
      <tbody>
        {ombrelloni.map((ombrellone, index) => {
          return (
            <tr key={index}>
              <td>
                <label>Fila</label>
                <span>{ombrellone.fila}</span>
              </td>
              <td>
                <label>Numero</label>
                <span>{ombrellone.posizione}</span>
              </td>
              <td>
                <label>Numero</label>
                <span>{ombrellone.numero_ombrellone}</span>
              </td>

              {/* <td>
                <img
                  className="qrim"
                  src={`data:image/png;base64,${ombrellone.qrCodeUrl}`}
                  alt="QR Code"
                />
                
                </td> */}

              <td>
                <div className="contenuto">
                  <MyButton
                    onClick={() => modifica(ombrellone.id_ombrellone)}
                    className="large-icon mybutton"
                    name={"contract_edit"}
                  tooltip={"Modifica"}

                  />

                  <MyButton
                    onClick={() => stampa(ombrellone.id_ombrellone)}
                    className="large-icon mybutton"
                    name={"qr_code_scanner"}
                  tooltip={"Stampa"}

                  />
                </div>
               {/*  <a
                  href="#fakeLink"
                  onClick={() => modifica(ombrellone.id_ombrellone)}
                  className="btn purple"
                >
                  Modifica
                </a>
                <a
                  href="#fakeLink"
                  onClick={() => stampa(ombrellone.id_ombrellone)}
                  className="btn purple"
                >
                  Stampa
                </a> */}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableOmbrelloni;
