import React, { useEffect } from "react";
import { useState } from "react";
import FirstTab from "../alltabordinitavolo/FirstTab";
import SecondTab from "../alltabordinitavolo/SecondTab";
import "../../style/Tabs.css";
import { set_coperti, set_ordine, set_tavolo, set_totale_generale } from "../../store/actions/ordini";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("aperti");
  

  return (
    <div className="Tabs">
      {/* Tab nav */}
      <div className="nav-tab">
        <a
          href="#fakeLink"
           onClick={() => setActiveTab("aperti")}
          className="btn purple"
        >
          Aperti
        </a>
        <a
          href="#fakeLink"
          onClick={() => setActiveTab("chiusi")}
          className="btn purple"
        >
          Chiusi
        </a>
      </div>
      <div className="outlet">
        {activeTab === "aperti" ? <FirstTab /> : <SecondTab  />}
      </div>
    </div>
  );
};
export default Tabs;
