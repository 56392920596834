import "../style/AntipastiInfo.css";
import MyButton from "./MyButton";

const Formule = (props) => {
  console.log("FROM DATA", props.formData);
  console.log("RAIL CARD", props.railCards);
  const handleSelectRailcard = (event) => {
    const index = event.target.getAttribute("data-index");
    let value = event.target.value;

    let piatto;
    piatto = props.portate.find((p) => p.id_formula == value);

    if (piatto) {
      var updatedList = [...props.formData.categorie_scelte, piatto];
      props.setFormData({ ...props.formData, categorie_scelte: updatedList });
    }

    const updatedRailCards =
      props.railCards &&
      props.railCards.map((i) => {
        if (i.id === +index) {
          i.value = value;
          i.dis = "1";
        }
        return i;
      });
    props.setRailCards(updatedRailCards);
  };

  const handleRemoveRailcard = (id, value) => {
    let rail = props.railCards && props.railCards.find((p) => p.id === id);

    let updatedRailCards;
    var updatedList = [...props.formData.categorie_scelte];
    if (rail) {
      updatedRailCards =
        props.railCards && props.railCards.filter((r) => r.id !== id);
      updatedList = props.formData.categorie_scelte.filter(
        (r) => r.id_formula != rail.value
      );
    }

    props.setFormData({ ...props.formData, categorie_scelte: updatedList });

    props.setRailCards(updatedRailCards);
  };

  return (
    <div className="conteiner-antipasti">
      <div className="piatti">
        {props.railCards &&
          props.railCards.map(({ value, fieldName, id, dis, nome }, index) => {
            return (
              <div key={id} className="container_box">
                <select
                  value={value}
                  name={fieldName}
                  className="select"
                  data-index={id}
                  onChange={handleSelectRailcard}
                >
                  {dis == "0" ? (
                    <>
                      <option value={value}>
                        -- Seleziona una Categoria --
                      </option>

                      {props.portate.map((portata, index) => {
                        if (
                          !props.railCards ||
                          !props.railCards.find(
                            (p) => p.value == portata.id_formula
                          )
                        ) {
                          if (portata.id_formula != 1) {
                            return (
                              <option key={index} value={portata.id_formula}>
                                {portata.nome}
                              </option>
                            );
                          }
                        }
                      })}
                    </>
                  ) : dis == "2" ? (
                    <>
                      <option defaultValue={id}>{nome} </option>
                      {props.portate.map((portata, index) => {
                        return (
                          <option
                            key={index}
                            value={portata.id_formula}
                            selected
                            disabled
                          >
                            {portata.nome}
                          </option>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <option value="-1">-- Seleziona una Categoriaaa --</option>
                      {props.portate.map((portata, index) => {
                        if (portata.id_formula == value)
                          return (
                            <option
                              key={index}
                              value={portata.id_formula}
                              selected
                              disabled
                            >
                              {portata.nome}
                            </option>
                          );
                      })}
                    </>
                  )}
                </select>

                <MyButton
                    onClick={() => handleRemoveRailcard(id, value)}
                    className="large-icon mybutton"
                    name={"delete"}
                    tooltip={"Elimina"}
                  />
              </div>
            );
          })}
      </div>
      <MyButton
        onClick={() => props.handleRailCardCLick(props.page)}
        className="large-icon mybutton"
        name={"add"}
        tooltip={"Aggiungi"}
      />
    </div>
  );
};

export default Formule;
