import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/Spinner";
import TableStorico from "../components/TableStorico";
import { get_storico_ordini} from "../store/actions/ordini";
import "../style/Storico.css";

const Storico = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ordini.loading);

 const storico=useSelector((state) => state.ordini.storico_ordini);
 const id_ordine = useSelector((state) => state.ordini.id_ordine);

  useEffect(() => {
    async function fetchstorico() {
      dispatch(get_storico_ordini(id_ordine));
      
    }
    fetchstorico();
  }, []);
  return <div className="container-storico">
    {loading ? <Spinner/> :
      storico.length===0 ?  "non sono presenti ordini inviati" : <TableStorico storico={storico}/>
    }
  </div>;
};

export default Storico;
