import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { node } from "../Axios";
import { loading_login } from "../store/actions/login";
import { set_ordine, set_storico_ordine, togglenavcat } from "../store/actions/ordini";
import "../style/Home.css";
import "../style/Form.css";
import { getSession } from "../utils/session";
import Modal from "./ModalPrivacy";

const Home = () => {
  const dispatch = useDispatch();
  const ordini = useSelector((state) => state.ordini.ordini);

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [flag, setFlag] = useState(false);
  const [cek, setCek] = useState(false);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    async function fetchordini() {
      await dispatch(loading_login(true));
      await dispatch(togglenavcat(false));

      await dispatch(loading_login(false));
    }

    fetchordini();
  }, []);

  const toggleModal = async () => {
    setModal(!modal);
  };
  const handleChange = async (e) => {
    setEmail(e.target.value);
  };
  const handleReset = async (e) => {
    setError("");
  };
  const handleCheck = async (event) => {
    setCek(!cek);
  };
  const handleSubmit = async () => {
    if (!cek || email == "") {
      setError("Controlla la mail o accetta la privacy ");
      return;
    }
    setFlag(true);

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        mail: email,
      };
      const res = await node.post(
        "/api/email/aggiungiemail",
        bodyParameters,
        config
      );

      setError("Il tuo indirizzo è stato salvato correttamente");
      setEmail("");
    } catch (error) {}

    setFlag(false);
  };

  return (
    <div className="container-home">
      {modal ? <Modal toggleModal={toggleModal} /> : null}
      <div className="margin50">
        <h1>Come Utilizzare il sistema?</h1>
        <div className="info">
          Naviga tra le categorie nel menu di sinistra e aggiungi i prodotti che
          desideri ordinare. Una volta che avete finito di ordinare, andate nel
          carrello e inviate l'ordine. Da quel momento in poi, tutto ciò che
          avete ordinato sarà preparato e servito. Se desiderate ordinare
          qualcosa che sarà servito successivamente, basta ripetere i passaggi
          precedenti
          <h1>Semplice no?</h1>
        </div>
        <div className="raccolta">
          <div className="mail">
            Inserisci la tua mail per essere aggiornato sui nostri eventi,
            prezzi, offerte e coupon{" "}
          </div>
          <div className="spazio_input">
            <input
              id="first-name"
              className="campo_input"
              type="email"
              name="email"
              value={email}
              onChange={handleChange}
              onFocus={handleReset}
            />
            <span className="campo_label" aria-hidden="true">
              <span className="field__label">Email</span>
            </span>
            {error != "" ? <p>{error}</p> : null}
          </div>
          {error != "" ? <p>{error}</p> : null}
          <div className="ingr">
            <input
              type="checkbox"
              onChange={handleCheck}
              checked={cek ? true : false}
            />
            <span>
              <a href="#fakeLink" onClick={toggleModal}>
                Accetta la privacy
              </a>
            </span>
          </div>

          {!flag ? (
            <a
              href="#fakeLink"
              onClick={handleSubmit}
              className="btn purple"
              type="submit"
            >
              Iscriviti
            </a>
          ) : (
            "Attendi"
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
