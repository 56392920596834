import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { loading_login } from "../store/actions/login";
import Spinner from "../components/Spinner";

import {
  salva_ingrediente,
  modifica_ingrediente,
} from "../store/actions/ordini";
import "../style/Piatti.css";
import "../style/Form.css";
import SelectCategorieIngredienti from "../components/SelectCategorieIngredienti";
import SelectUM from "../components/SelectUM";
import Categorie from "../components/Categorie";

const Ingrediente = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categorieall = useSelector((state) => state.ordini.categorieall);
  const [railCards, setRailCards] = useState([]);

  const categorie_ingredienti = useSelector(
    (state) => state.ordini.categorie_ingredienti
  );
  const loading2 = useSelector((state) => state.login.loading);
  const ingrediente = useSelector((state) => state.ordini.ingrediente);
  const { id } = useParams();
  const handleRailCardCLick = () => {
    let id = Math.floor(Math.random() * 1000000);
    setRailCards([
      ...railCards,
      {
        value: "",
        fieldName: `railcard-${railCards.length + 1}`,
        count: railCards.length + 1,
        id,
        dis: "0",
      },
    ]);
  };
  let [formData, setFormData] = useState(
    ingrediente.length != 0
      ? {
          id_ingredienti: ingrediente[0].id_ingredienti,
          nome: ingrediente[0].nome,
          prezzo: ingrediente[0].prezzo,
          id_categorie_ingredienti:
            ingrediente[0].categorie_ingredienti.id_categorie_ingredienti,
          unita_di_misura: ingrediente[0].unita_di_misura,
          categorie_scelte: [],
        }
      : {
          id_ingredienti: "",
          nome: "",
          id_categorie_ingredienti: "",
          prezzo: "",
          unita_di_misura: "",
          categorie_scelte: [],
        }
  );
  const unitadimisura = [
    { id: 1, nome: "pz" },
    { id: 2, nome: "gr" },
  ];

  useEffect(() => {
    if (ingrediente.length != 0) {
      var updatedList = [];
      setRailCards(
        ingrediente[0].rel_ingr_categories.map((comment) => ({
          value: comment.categorie.id_categoria,
          nome: comment.categorie.nome,
          fieldName: `railcard-${railCards.length + 1}`,
          count: railCards.length + 1,
          id: comment.categorie.id_categoria,
          dis: "2",
        }))
      );

      ingrediente[0].rel_ingr_categories.forEach((comment) => {
        updatedList.push(comment.categorie);
      });

      setFormData({ ...formData, categorie_scelte: updatedList });
    }
  }, [ingrediente]);

  const salvaIngrediente = async () => {
    await dispatch(loading_login(true));
    if (await dispatch(salva_ingrediente(formData))) navigate("/ingredienti");
    await dispatch(loading_login(false));
  };
  const modificaIngrediente = async () => {
    await dispatch(loading_login(true));
    if (await dispatch(modifica_ingrediente(formData)))
      navigate("/ingredienti");
    await dispatch(loading_login(false));
  };
  return (
    <div className="page margin50">
      {formData.length == 0 || formData == null || loading2 ? (
        <Spinner />
      ) : (
        <div className="blocco_page_container">
          <div className="spazio_input">
            <input
              id="first-name"
              type="text"
              className="campo_input"
              placeholder="Inserisci il nome dell'ingrediente"
              onChange={(e) =>
                setFormData({ ...formData, nome: e.target.value })
              }
              value={formData.nome}
            />
            <span className="campo_label" aria-hidden="true">
              <span className="field__label">Nome</span>
            </span>
          </div>

          {
            <div className="container-ruoli">
              <SelectCategorieIngredienti
                formData={formData}
                setFormData={setFormData}
                categorieall={categorie_ingredienti}
              />
            </div>
          }
          {
            <div className="container-ruoli">
              <SelectUM
                formData={formData}
                setFormData={setFormData}
                unitadimisura={unitadimisura}
              />
            </div>
          }
          <div className="spazio_input">
            <input
              id="last-name"
              type="number"
              className="campo_input"
              placeholder="Es. 4.5"
              onChange={(e) =>
                setFormData({ ...formData, prezzo: e.target.value })
              }
              value={formData.prezzo}
            />
            <span className="campo_label" aria-hidden="true">
              <span className="field__label">Prezzo (€)</span>
            </span>
          </div>

          <div className="container-categorie">
            <h3>Associa l'ingrediente ad una o piu categorie</h3>
            <Categorie
              portate={categorieall}
              formData={formData}
              setFormData={setFormData}
              railCards={railCards}
              setRailCards={setRailCards}
              handleRailCardCLick={handleRailCardCLick}
            />
          </div>
          <div className="contenittore_bottoni">
            {id == 0 ? (
              <a
                href="#fakeLink"
                onClick={() => salvaIngrediente(formData)}
                className="btn purple"
              >
                Inserisci
              </a>
            ) : (
              <a
                href="#fakeLink"
                onClick={() => modificaIngrediente(formData)}
                className="btn purple"
              >
                Modifica
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Ingrediente;
