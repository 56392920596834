import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "../style/Table.css";

const TableOrdiniAperti = ({
  ordini,
  chiudiordine,
  ordina,
  ruolo,
  storico,
}) => {
  let date_format;
  return (
    <table>
      <thead>
        <th>N° Ordine</th>
        <th>Data</th>
        <th>Nome</th>
        <th>Pin</th>
        <th>Totale(€)</th>
        <th>Action</th>
      </thead>
      <tbody>
        {ordini.map((ordine, index) => {
          date_format = new Date(ordine.createdAt);
          return (
            <tr key={index}>
              <td>
                <label>N° Ordine</label>
                <span>{ordine.id_ordine}</span>
              </td>
              <td>
                <label>Data</label>
                <span>{date_format.toLocaleString()}</span>
              </td>
              <td>
                <label>Nome</label>
                <span>
                  {ordine.ordini_ombrellonis[0].persone.nome}{" "}
                  {ordine.ordini_ombrellonis[0].persone.cognome}
                </span>
              </td>
              <td>
                <label>Pin</label>
                <span>{ordine.ordini_ombrellonis[0].pin}</span>
              </td>

              <td>
                <label>Totale(€)</label>
                <span>{ordine.totale} €</span>
              </td>

              <td>
                {ruolo == 1 || ordine.stato == 1 ? (
                  <a
                    href="#fakeLink"
                    onClick={() =>
                      ordina(
                        ordine.id_ordine,

                        ordine.totale,

                        ordine.ordini_ombrellonis[0].formuleIdFormula,
                        ordine.storico,
                        ordine.storico_uscite,
                        ordine.tipo
                      )
                    }
                    className="btn purple"
                  >
                    Ordina
                  </a>
                ) : null}
                {ordine.stato == 1 ? null : (
                  <a
                    href="#fakeLink"
                    onClick={() => chiudiordine(ordine.id_ordine, ordine.tipo)}
                    className="btn purple"
                  >
                    Chiudi
                  </a>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableOrdiniAperti;
