import React from "react";
import { useSelector } from "react-redux";
import { NavLink, Outlet } from "react-router-dom";
import "../style/Bottombar.css";

const Bottombar = (props) => {
  const qty_prodotti = useSelector((state) => state.ordini.qty_prodotti);
  const tot_prezzo = useSelector((state) => state.ordini.tot_prezzo);
  const coperti = useSelector((state) => state.ordini.coperti);
  const tot_generale = useSelector((state) => state.ordini.tot_generale);
  const tavolo = useSelector((state) => state.ordini.tavolo);
  const togglemenu = useSelector((state) => state.ordini.togglemenu);
  const qty_bibite = useSelector((state) => state.ordini.qty_bibite);
  const id_formula = useSelector((state) => state.ordini.id_formula);
  const storico = useSelector((state) => state.ordini.storico);

  return (
    <div className="container_bottombar">
      <div className="gruppo_icone">
        <div className="menu-bottombar tavolo">
          <span className="material-symbols-rounded">restaurant</span>
          <label>{coperti}</label>
        </div>
        <div className="menu-bottombar"></div>
        {!tot_generale == 0.0 ? (
          <>
            <div className="menu-bottombar">
              <span className="material-symbols-rounded">payments</span>
              <label>€ {tot_generale.toFixed(2)}</label>
            </div>
            <div className="menu-bottombar"></div>
          </>
        ) : null}
        <div className="menu-bottombar">
          <span className="material-symbols-rounded">shopping_bag</span>
          <label>€ {tot_prezzo.toFixed(2)}</label>
        </div>
        <div className="menu-bottombar">
          <span className="material-symbols-rounded">table_restaurant</span>
          <label>{tavolo} </label>
        </div>
        {/* ci sta ma non si vede lo storico
         */}
        {storico === 1 ? (
          <NavLink to="/storico">
            <div className="menu-bottombar">
              <span className="material-symbols-rounded">receipt_long</span>
              <label>Storico </label>
            </div>
          </NavLink>
        ) : null}
      </div>
    </div>
  );
};
export default Bottombar;
