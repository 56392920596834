import {
  IDRUOLO,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  GETMENU_START,
  GETMENU_SUCCESS,
  GETMENU_FAIL,
  LOGIN_CHECK,
  ATTESANUOVO,
  GET_CATEGORIE_START,
  GET_CATEGORIE_SUCCESS,
  GET_CATEGORIE_FAIL,
  LOADING,
  MQTT_CLIENT_DISCONNECTED,
  MQTT_CLIENT_CONNECTED,
  MQTT_CONNECTION_ERROR,
  FETCH_TOPICS_SUCCESS,
  SET_MQTT_OPTIONS,

} from "../actions/login";

const initialState = {
  isLogin: "",
  attesanuovo: "",
  ruolo: [],
  categorie:[],
  menu:[],
  appcode: null,
  id_persona: null,
  id_ruolo: null,
  token: "",
  loading: false,
  error: false,
  connected: false,
  topics: null,
  mqttOptions: null,
  data: null,
  client:null
 

};

function login(state = initialState, action) {
  switch (action.type) {
    case MQTT_CLIENT_CONNECTED:
      return {
        ...state,
        connected: true,
        client: action.payload,
      };
    case MQTT_CLIENT_DISCONNECTED:
      return {
        ...state,
        connected: false,
        client: null,
      };
    case MQTT_CONNECTION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_TOPICS_SUCCESS:
      return {
        ...state,
        topics: action.payload,
        error: null,
      };
    
    case SET_MQTT_OPTIONS:
      return {
        ...state,
        mqttOptions: action.payload,
        error: null,
      };
    case IDRUOLO:
      return {
        ...state,
        id_ruolo: action.id_ruolo,
      };

    case LOGIN_START:
      return {
        ...state,
        loading: true,
      };


    case LOGIN_SUCCESS:
      return {
        ...state,
        ruolo: action.res.data.ruoliutente,
        appcode: action.res.data.id_appcode,
        id_persona: action.res.data.id_persona,

        loading: false,
        error: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case GETMENU_START:
      return {
        ...state,
        loading: true,
      };
    case GETMENU_SUCCESS:
      return {
        ...state,
        token: action.res.data.token,
        menu: action.res.data.menu,
        isLogin: true,

        loading: false,
        error: false,
      };
    case GETMENU_FAIL:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case LOGIN_CHECK:
      return {
        ...state,
        isLogin: true,
        token: action.sessione.token,
        menu: action.sessione.menu,
        id_ruolo:action.sessione.ruolo
      };
   
    
    case ATTESANUOVO:
      return {
        ...state,
        attesanuovo: action.attesanuovo,
      };

      case GET_CATEGORIE_START:
      return {
        ...state,
        
        loading: true,
        error: false,
      };
      case GET_CATEGORIE_SUCCESS:
        return {
          ...state,
          categorie: action.res.data.categorie,
          loading: false,
          error: false,
        };
    case GET_CATEGORIE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        
      };
      case LOADING:
        return {
          ...state,
          loading: action.value,
         
          
        };
    default:
      return state;
  }
}

export default login;
