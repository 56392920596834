import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { loading_login } from "../store/actions/login";
import Spinner from "../components/Spinner";

import {
  salva_ingrediente,
  modifica_ingrediente,
  salva_ombrellone,
  modifica_ombrellone,
  stampa_ombrellone,
} from "../store/actions/ordini";
import "../style/Piatti.css";
import "../style/Form.css";
import SelectCategorieIngredienti from "../components/SelectCategorieIngredienti";
import SelectUM from "../components/SelectUM";
import Categorie from "../components/Categorie";

const Ombrellone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const loading2 = useSelector((state) => state.login.loading);
  const ombrellone = useSelector((state) => state.ordini.ombrellone);
  const error = useSelector((state) => state.ordini.error);
  const errortext = useSelector((state) => state.ordini.errortext);

  let [formData, setFormData] = useState(
    ombrellone.length != 0
      ? {
          id_ombrellone: ombrellone[0].id_ombrellone,
          fila: ombrellone[0].fila,
          posizione: ombrellone[0].posizione,
          numero_ombrellone: ombrellone[0].numero_ombrellone,
        }
      : {
          id_ombrellone: "",
          fila: "",
          posizione: "",
          numero_ombrellone: "",
        }
  );

  const salvaOmbbrellone = async () => {
    await dispatch(loading_login(true));
  
    if (await dispatch(salva_ombrellone(formData))) {
      navigate("/ombrelloni");
    }
    await dispatch(loading_login(false));
  };
  const modificaOmbbrellone = async () => {
    await dispatch(loading_login(true));
    if (await dispatch(modifica_ombrellone(formData))) {
      navigate("/ombrelloni");
    }
    await dispatch(loading_login(false));
  };

  return (
    <div className="page">
      {formData.length == 0 || formData == null || loading2 ? (
        <Spinner />
      ) : (
        <div className="blocco_page_container">
          <div className="container-piatti margin50">
            <h3>Nuovo Ombrellone</h3>
            <div className="spazio_input">
              <input
                id="first-name"
                type="number"
                className="campo_input"
                onChange={(e) =>
                  setFormData({ ...formData, fila: e.target.value })
                }
                value={formData.fila}
              />
              <span className="campo_label" aria-hidden="true">
                <span className="field__label">Fila</span>
              </span>
            </div>
            <div className="spazio_input">
              <input
                id="first-name"
                type="number"
                className="campo_input"
                onChange={(e) =>
                  setFormData({ ...formData, posizione: e.target.value })
                }
                value={formData.posizione}
              />
              <span className="campo_label" aria-hidden="true">
                <span className="field__label">Posizione</span>
              </span>
            </div>
            <div className="spazio_input">
              <input
                id="first-name"
                type="number"
                className="campo_input"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    numero_ombrellone: e.target.value,
                  })
                }
                value={formData.numero_ombrellone}
              />
              <span className="campo_label" aria-hidden="true">
                <span className="field__label">Numero</span>
              </span>
            </div>
          </div>

          {error ? <span className="error">{errortext}</span> : null}

          {id == 0 ? (
            <a
              href="#fakeLink"
              onClick={() => salvaOmbbrellone(formData)}
              className="btn purple"
            >
              Inserisci
            </a>
          ) : (
            <a
              href="#fakeLink"
              onClick={() => modificaOmbbrellone(formData)}
              className="btn purple"
            >
              Modifica
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default Ombrellone;
