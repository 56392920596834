import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "../style/InputOrdine.css";
import "../style/Form.css";
import "../style/MySelect.css";
import { getSession } from "../utils/session";
import SelectFormule from "./SelectFormule";

const InputOmbrellone = ({
  formData,
  setFormData,
  nuovoordine,
  ombrelloni,
  tariffe,
  formErrors,
  checkEmailValidity,
  emailValid,
  setEmailValid,
  emailSuggestions,
  setEmailSuggestions,
}) => {
  const baseTariffe = tariffe.filter((tariffa) => tariffa.tipo === "Base");
  const extraTariffe = tariffe.filter((tariffa) => tariffa.tipo === "Extra");

  const handleQuantityChange = (tariffaId, quantity, prezzo) => {
    const updatedTariffeExtra = formData.tariffe_extra.map((tariffaExtra) =>
      tariffaExtra.id_tariffa_ombrellone === tariffaId
        ? { ...tariffaExtra, quantity: Number(quantity) }
        : tariffaExtra
    );

    const existingTariffaExtra = updatedTariffeExtra.find(
      (tariffaExtra) => tariffaExtra.id_tariffa_ombrellone === tariffaId
    );

    if (!existingTariffaExtra) {
      updatedTariffeExtra.push({
        id_tariffa_ombrellone: tariffaId,
        quantity: Number(quantity),
        prezzo: Number(prezzo),
      });
    }
    let totalExtra = 0;

    for (const tariffaExtra of updatedTariffeExtra) {
      totalExtra += tariffaExtra.quantity * tariffaExtra.prezzo;
    }

    setFormData({
      ...formData,
      tariffe_extra: updatedTariffeExtra,
      totale_extra: totalExtra,
      totale: totalExtra + formData.totale_base,
    });
  };

  const handleTariffaChange = (e) => {
    const selectedTariffaId = e.target.value;

    const baseTariffa = baseTariffe.find(
      (tariffa) => tariffa.id_tariffa_ombrellone == selectedTariffaId
    );

    setFormData({
      ...formData,
      tariffa: selectedTariffaId,
      totale_base: baseTariffa ? Number(baseTariffa.prezzo_tariffa) : 0,
      totale:
        formData.totale_extra + baseTariffa
          ? Number(baseTariffa.prezzo_tariffa)
          : 0,
    });
  };

  return (
    <div className="page margin50">
      <div className="spazio_input">
        <input
          id="email"
          type="email"
          className="campo_input"
          placeholder="Inserisci la e-mail"
          onChange={(e) => {
            setFormData({ ...formData, email: e.target.value });
            checkEmailValidity(e.target.value);
          }}
          value={formData.email}
        />
        {formErrors.email && <span className="error">{formErrors.email}</span>}
        {emailSuggestions.length > 0 && (
          <ul className="email-suggestions">
            {emailSuggestions.map((suggestion, index) => (
              <li key={index}>{suggestion}</li>
            ))}
          </ul>
        )}
        <span className="campo_label" aria-hidden="true">
          <span className="field__label">Email</span>
        </span>
      </div>
      <div className="spazio_input">
        <input
          id="first-name"
          type="text"
          className="campo_input"
          placeholder="Inserisci il nome"
          onChange={(e) => setFormData({ ...formData, nome: e.target.value })}
          value={formData.nome}
        />
        {formErrors.nome && <span className="error">{formErrors.nome}</span>}
        <span className="campo_label" aria-hidden="true">
          <span className="field__label">Nome</span>
        </span>
      </div>
      <div className="spazio_input">
        <input
          id="first-name"
          type="text"
          className="campo_input"
          placeholder="Inserisci il cognomeo"
          onChange={(e) =>
            setFormData({ ...formData, cognome: e.target.value })
          }
          value={formData.cognome}
        />
        {formErrors.cognome && (
          <span className="error">{formErrors.cognome}</span>
        )}

        <span className="campo_label" aria-hidden="true">
          <span className="field__label">Cognome</span>
        </span>
      </div>

      <div className="spazio_input">
        <input
          id="first-name"
          type="date"
          className="campo_input"
          placeholder="Inserisci la data di nascita"
          onChange={(e) =>
            setFormData({ ...formData, datanascita: e.target.value })
          }
          value={formData.datanascita}
        />
        {formErrors.datanascita && (
          <span className="error">{formErrors.datanascita}</span>
        )}

        <span className="campo_label" aria-hidden="true">
          <span className="field__label">Data di Nascita</span>
        </span>
      </div>
      <div className="spazio_input">
        <input
          id="first-name"
          type="text"
          className="campo_input"
          placeholder="Inserisci il telefono"
          onChange={(e) =>
            setFormData({ ...formData, telefono: e.target.value })
          }
          value={formData.telefono}
        />
        {formErrors.telefono && (
          <span className="error">{formErrors.telefono}</span>
        )}

        <span className="campo_label" aria-hidden="true">
          <span className="field__label">Telefono</span>
        </span>
      </div>
      <div className="spazio_input">
        <select
          value={formData.ombrellone}
          onChange={(e) =>
            setFormData({ ...formData, ombrellone: e.target.value })
          }
          className="select"
        >
          <option value="">Seleziona una ombrellone</option>
          {ombrelloni &&
            ombrelloni.map((ombrellone) => (
              <option
                key={ombrellone.id_ombrellone}
                value={ombrellone.id_ombrellone}
              >
                Fila: {ombrellone.fila} - Posizione: {ombrellone.posizione}
              </option>
            ))}
        </select>
        {formErrors.ombrellone && (
          <span className="error">{formErrors.ombrellone}</span>
        )}
      </div>
      <div className="spazio_input">
        <select
          value={formData.tariffa}
          onChange={handleTariffaChange}
          className="select"
        >
          <option value="">Seleziona una tariffa</option>
          {baseTariffe &&
            baseTariffe.map((tariffa) => (
              <option
                key={tariffa.id_tariffa_ombrellone}
                value={tariffa.id_tariffa_ombrellone}
              >
                {tariffa.descrizione} - {tariffa.prezzo_tariffa.toFixed(2)} E
              </option>
            ))}
        </select>
      </div>

      {extraTariffe.length > 0 && (
        <div>
          {extraTariffe.map((tariffa) => {
            const tariffaExtra = formData.tariffe_extra.find(
              (te) => te.id_tariffa_ombrellone === tariffa.id_tariffa_ombrellone
            );
            return (
              <div key={tariffa.id_tariffa_ombrellone} className="spazio_input">
                <label>
                  {tariffa.descrizione} - {tariffa.prezzo_tariffa.toFixed(2)} E
                </label>
                <input
                  type="number"
                  value={tariffaExtra ? tariffaExtra.quantity : 0}
                  onChange={(e) =>
                    handleQuantityChange(
                      tariffa.id_tariffa_ombrellone,
                      e.target.value,
                      tariffa.prezzo_tariffa
                    )
                  }
                />
                <span>
                  Totale:{" "}
                  {tariffaExtra
                    ? (tariffaExtra.quantity * tariffa.prezzo_tariffa).toFixed(
                        2
                      )
                    : "0.00"}
                  E
                </span>
              </div>
            );
          })}
          <div className="spazio_input">
            <span>Stai spendendo : {formData.totale.toFixed(2)} E</span>
          </div>
          <div className="spazio_input">
            <span>Base : {formData.totale_base.toFixed(2)} E</span>
          </div>
          <div className="spazio_input">
            <span>Extra : {formData.totale_extra.toFixed(2)} E</span>
          </div>
        </div>
      )}

      <a href="#fakeLink" onClick={nuovoordine} className="btn" disabled={true}>
        Assegna
      </a>
    </div>
  );
};

export default InputOmbrellone;
