import { useEffect, useState } from "react";
import "../style/ApriTavolo.css";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { node } from "../Axios";
import InputOmbrellone from "../components/InputOmbrellone";
import Spinner from "../components/Spinner";
import { loading_login, logout } from "../store/actions/login";
import {
  get_formule,
  get_ombrelloni_disponibili,
  get_tariffe,
  set_ordine,
} from "../store/actions/ordini";
import { getSession } from "../utils/session";

function Assegna_Ombrellone() {
  const dispatch = useDispatch();
  const loading2 = useSelector((state) => state.login.loading);
  const tariffe = useSelector((state) => state.ordini.tariffe);
  const ombrelloni = useSelector((state) => state.ordini.ombrelloni);
  const [emailValid, setEmailValid] = useState(false);
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const navigate = useNavigate();
  let [formData, setFormData] = useState({
    nome: "",
    cognome: "",
    email: "",
    datanascita: "",
    telefono: "",
    ombrellone: "",
    tariffa: "-1",
    tariffe_extra: [],
    totale: 0,
    totale_extra: 0,
    totale_base: 0,
  });
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (!formData.nome) {
      errors.nome = "Il nome è obbligatorio.";
    }

    if (!formData.cognome) {
      errors.cognome = "Il cognome è obbligatorio.";
    }
    if (!formData.email) {
      errors.email = "La mail è obbligatoria.";
    }
    if (!formData.datanascita) {
      errors.datanascita = "La datra di nascita è obbligatoria.";
    }
    if (!formData.telefono) {
      errors.telefono = "Il telefono è obbligatorio.";
    }
    if (!formData.ombrellone) {
      errors.ombrellone = "L'ombrellone è obbligatorio.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    async function getFunction() {
      await dispatch(get_tariffe());
      await dispatch(get_ombrelloni_disponibili());
    }

    getFunction();
  }, []);
  const checkEmailValidity = async (email) => {
    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };

      const bodyParameters = {
        email: email,
      };
      const res = await node.post(
        "/api/ordini/emailcheck",
        bodyParameters,
        config
      );
      console.log(res);

      setEmailSuggestions(res.data.suggestions);
      setFormData({
        ...formData,
        nome: res.data.suggestions.nome || "",
        cognome: res.data.suggestions.cognome || "",
        datanascita: res.data.suggestions.data_nascita || "",
        telefono: res.data.suggestions.telefono || "",
        email: email,
      });

      setEmailValid(true);
    } catch (error) {
      console.error("Error checking email validity:", error);
    }
  };

  //TODO da sistemare in thunk
  const nuovoordineombrellone = async () => {
    try {
      const isFormValid = validateForm();

      if (isFormValid) {
        await dispatch(loading_login(true));
        const sessione = await getSession();
        const config = {
          headers: { Authorization: `Bearer ${sessione.token}` },
        };

        const bodyParameters = {
          formData: formData,
        };
        const res = await node.post(
          "/api/ordini/nuovoordinetabletombrellone",
          bodyParameters,
          config
        );
        console.log(res);
        await dispatch(loading_login(false));

        navigate("/ordini_ombrelloni");
      }
    } catch (error) {
      console.log(error);

      if (error.response.status == "401") {
        dispatch(logout());
      }
    }
  };
  return (
    <div className="prodotti">
      {loading2 ? (
        <Spinner />
      ) : (
        <InputOmbrellone
          formData={formData}
          setFormData={setFormData}
          nuovoordine={nuovoordineombrellone}
          ombrelloni={ombrelloni}
          tariffe={tariffe}
          formErrors={formErrors}
          checkEmailValidity={checkEmailValidity}
          emailValid={emailValid}
          setEmailValid={setEmailValid}
          emailSuggestions={emailSuggestions}
          setEmailSuggestions={setEmailSuggestions}
        />
      )}
    </div>
  );
}

export default Assegna_Ombrellone;
