import React, { useState } from "react";
import "../style/MySelect.css";

const MySelect = ({ ruoli, getMenu }) => {
  const [value, setValue] = useState("");
  const handleValue = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="select">
      <select
        className="select"
        aria-label="Default select example"
        value={value}
        onChange={handleValue}
       
      >
        <option value="-1">-- Seleziona un ruolo --</option>
        {ruoli.map((ruolo) => (
          <option key={ruolo.id_ruolo} value={ruolo.id_ruolo}>
            {ruolo.nome_ruolo}
          </option>
        ))}
      </select>
      <button className="" onClick={() => getMenu(value)}>
        Conferma
      </button>
    </div>
  );
};
export default MySelect;
