import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/Spinner";

import { loading_login, logout} from "../store/actions/login";
import "../style/Logout.css";

function Logout() {
  const loading = useSelector((state) => state.login.loading);
  const client = useSelector((state) => state.login.client);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchlogout() {
      await dispatch(loading_login(true));

      await dispatch(logout(client));
      await dispatch(loading_login(false));
    }
    fetchlogout();
  }, []);

  return <div className="login">{loading ? <Spinner /> : null}</div>;
}

export default Logout;
