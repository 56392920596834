import React from "react";
import "../style/Table.css";
import MyButton from "./MyButton";

const TablePortate = ({ portate, modifica, toggle }) => {
  console.log("portate", portate);

  return (
    <table>
      <thead>
        <th>Categorie</th>
        <th>Prodotto</th>
        <th>Ingredienti</th>
        <th>Prezzo(€)</th>
        <th>offerta(€)</th>
        <th>Action</th>
      </thead>
      <tbody>
        {portate.map((portata, index) => (
          <tr key={index}>
            <td>
              <label>Categorie</label>
              <span>{portata.categorie.nome}</span>
            </td>
            <td>
              <label>Categorie</label>
              <span>{portata.nome}</span>
            </td>
            <td>
              <label>Categorie</label>
              <span>{portata.ingredienti_it}</span>
            </td>
            <td>
              <label>Categorie</label>
              <span>{portata.prezzo.toFixed(2)} €</span>
            </td>
            <td>
              <label>Categorie</label>
              <span>{portata.prezzo_offerte.toFixed(2)} €</span>
            </td>
            <td>
              <div className="contenuto">
                <MyButton
                  onClick={() => modifica(portata.id_prodotto)}
                  className="large-icon mybutton"
                  tooltip={"Modifica"}

                  name={"contract_edit"}
                />

                <MyButton
                  onClick={() => toggle(portata.id_prodotto, portata.stato)}
                  className="large-icon mybutton"
                  tooltip={portata.stato == 0 ? "Attiva" : "Disattiva"}
                  name={portata.stato == 0 ? "visibility" : "visibility_off"}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TablePortate;
