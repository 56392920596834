import React from "react";
import "../style/AntipastiInfo.css";

const PrezzoStatoInfo = (props) => {

  return (
    <div className="conteiner-antipasti">
        <div className="spazio_input">
          <input
            id="first-name"
            type="text"
            className="campo_input"
            placeholder="Nome del piatto"
            required
            onChange={(e) =>
              props.setFormData({ ...props.formData, nome: e.target.value })
            }
            value={props.formData.nome}
          />
          <span className="campo_label" aria-hidden="true">
            <span className="field__label">Nome</span>
          </span>
        </div>
        <div className="spazio_input">
            <input
              id="last-name"
              type="number"
              className="campo_input"
              placeholder="Es. 4.5"
              onChange={(e) =>
                props.setFormData({ ...props.formData, prezzo: e.target.value })
              }
              value={props.formData.prezzo}
            />
            <span className="campo_label" aria-hidden="true">
              <span className="field__label">Prezzo (€)</span>
            </span>
          </div>
          <div className="field field_v2">
            <input
              id="last-name"
              type="file"
              className="campo_input"
              onChange={props.fileChangedHandler}
            />
          </div>
    </div>
  );
};
export default PrezzoStatoInfo;
