import React, { useState } from "react";
import "../style/MySelect.css";

const SelectUM = ({ formData, setFormData, unitadimisura }) => {
  const [value, setValue] = useState("");
  const handleValue = (e) => {
    setValue(e.target.value);
  };

  return (
  
        <select
          className="select"
          aria-label="Default select example"
          onChange={(e) =>
            setFormData({ ...formData, unita_di_misura: e.target.value })
          }
          value={formData.unita_di_misura}
        >
          <option value="-1">-- Seleziona una UM --</option>
          {unitadimisura.map((unita) => (
            <option key={unita.id} value={unita.nome}>
              {unita.nome}
            </option>
          ))}
        </select>
   
  );
};
export default SelectUM;
