import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/Spinner";

import {  logout_massivo,loading_login } from "../store/actions/login";
import "../style/Logout.css";

function LogoutMassivo() {
  const loading = useSelector((state) => state.login.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchlogout() {
      await dispatch(loading_login(true));

      await dispatch(logout_massivo());
      await dispatch(loading_login(false));

    }
    fetchlogout();
  }, []);

  return <div className="login">{loading ? <Spinner /> : "Tutti i Tablet sono stasti Disconnessi!!"}</div>;
}

export default LogoutMassivo;
