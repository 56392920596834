import React, { useState } from "react";
import "../style/FornoInfo.css";

const FornoInfo = (props) => {
  const checkList = props.ingredienti;

  const handleCheck = async (event) => {
 
    let ing = props.ingredienti.find(
      (p) => p.id_ingredienti == event.target.value
    );
   
    var updatedList = [...props.formData.ingredienti_forno];
    if (event.target.checked) {
      updatedList = [
        ...props.formData.ingredienti_forno,
        props.ingredienti.find((p) => p.id_ingredienti == event.target.value),
      ];
      await props.setFormData({
        ...props.formData,
        ingredienti_forno: updatedList,
        totale: props.id_formula==1 ?  Number(props.formData.totale) +Number(ing.prezzo) : 0,
      });

    } else {
      updatedList.splice(
        props.formData.ingredienti_forno.findIndex(
          (p) => p.id_ingredienti == event.target.value
        ),
        1
      );
     await props.setFormData({
        ...props.formData,
        ingredienti_forno: updatedList,
        totale:  props.id_formula==1  ? Number(props.formData.totale) - Number(ing.prezzo) : 0,
      });

    }
  };
  console.log("ingrediente forno dopo  ",props.formData.ingredienti_forno)
 

  const [isActive, setActive] = useState("false");



  return (
    <div className="container-fuorinfo">
    <div className="container-ing">
      {checkList.map((item, index) => {
        if (
          item.categorie_ingredienti.nome !== "Basi per Pizza Pesonalizzata" &&
          item.categorie_ingredienti.nome !==
            "Pane per Hamburger Personalizzato" &&
          item.categorie_ingredienti.nome !== "Carne per Hamburger" &&
          item.categorie_ingredienti.nome !== "Salse per Guarnizione"
        ) {
          return (
            /* CHECKA E DECHECKA A MINKIA!!!!! */
            <label className={`ingr `} key={index}>
              <input
              
                value={item.id_ingredienti}
                type="checkbox"
                onChange={handleCheck}
                checked={
                  !props.formData.ingredienti_forno.find(
                    (p) => p.id_ingredienti == item.id_ingredienti
                  )
                    ? false
                    : true
                }
              />
              {item.nome} ({props.id_formula==1 ? item.prezzo:0} €)
            </label>
          );
        }
      })}
    </div>
    </div>
  );
};
export default FornoInfo;
