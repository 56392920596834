import "../style/AntipastiInfo.css";
import MyButton from "./MyButton";

const AntipastiInfo = (props) => {
  const handleSelectRailcard = (event) => {
    const index = event.target.getAttribute("data-index");
    let value = event.target.value;
    let piatto;
    /*  props.portate.forEach((element) => {
      if (element.id_categoria == 2) { */
    piatto = props.portate.find((p) => p.id_prodotto == value);
    /*     }
    }); */

    // console.log("piatto", piatto);
    if (piatto) {
      var updatedList = [...props.formData.antipasti_scelti, piatto];
      props.setFormData({ ...props.formData, antipasti_scelti: updatedList });
    }

    const updatedRailCards =
      props.railCards &&
      props.railCards.map((i) => {
        if (i.id === +index) {
          i.value = value;
        }
        return i;
      });
    props.setRailCards(updatedRailCards);
  };

  const handleRemoveRailcard = (id, value) => {
    const updatedRailCards =
      props.railCards && props.railCards.filter((r) => r.id !== id);
    var updatedList = [...props.formData.antipasti_scelti];
    updatedList.splice(
      props.formData.antipasti_scelti.find((p) => p.id_prodotto == value),
      1
    );
    props.setFormData({ ...props.formData, antipasti_scelti: updatedList });

    props.setRailCards(updatedRailCards);
  };

  return (
    <div className="conteiner-antipasti">
      <div className="piatti">
        {props.railCards &&
          props.railCards.map(({ value, fieldName, id, page }, index) => {
            if (page === props.page) {
              return (
                <div key={id} className="container_box">
                  <select
                    value={value}
                    name={fieldName}
                    className="select"
                    data-index={id}
                    onChange={handleSelectRailcard}
                  >
                    <option value="-1">-- Seleziona un Antipasto --</option>
                    {props.portate.map((portata, index) => {
                      if (portata.categorie.id_categoria == 2) {
                        return (
                          <option key={index} value={portata.id_prodotto}>
                            {portata.nome}
                          </option>
                        );
                      }
                    })}
                  </select>
                  <MyButton
                    onClick={() => handleRemoveRailcard(id, value)}
                    className="large-icon mybutton"
                    name={"delete"}
                    tooltip={"Elimina"}
                  />
                </div>
              );
            }
          })}
      </div>
      <MyButton
        onClick={() => props.handleRailCardCLick(props.page)}
        className="large-icon mybutton"
        name={"add"}
        tooltip={"Aggiungi"}
      />
    </div>
  );
};

export default AntipastiInfo;
