import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { togglenav, togglenavcat } from "../store/actions/ordini";
import logo from "../assets/logo_menu_manager.png";

import "../style/Navbar.css";

const Navbar = () => {
  const dispatch = useDispatch();
  const categorie = useSelector((state) => state.login.categorie);
  const menu = useSelector((state) => state.login.menu);
  const id_ordine = useSelector((state) => state.ordini.id_ordine);
  const storico = useSelector((state) => state.ordini.storico);
  const togglemenu = useSelector((state) => state.ordini.togglemenu);
  const togglemenucat = useSelector((state) => state.ordini.togglemenucat);
  const id_formula = useSelector((state) => state.ordini.id_formula);

  const toggle = () => {
    dispatch(togglenav(!togglemenu));
  };

  return (
    <nav className="nav">
      <div className="menu_icon_container">
        <img src={logo}></img>

        <div className="menu_icon" onClick={toggle}>
          <span
            className="material-symbols-rounded"
            style={{
              marginTop: togglemenu ? "0" : "-36px",
            }}
          >
            menu
          </span>
          <span className="material-symbols-rounded">close</span>
        </div>
      </div>
      <div
        className="content-nav"
        style={{
          display: togglemenu ? "none" : "block",
        }}
      >
        <div className="content-nav-block">
          {menu.map((voce, index) => (
            <NavLink
              to={`${voce.nome}/`}
              className="line"
              style={({ isActive }) => ({
                color: isActive ? "#fff" : "#545e6f",
                background: isActive ? "#7600dc" : null,
              })}
              key={index}
              onClick={toggle}
            >
              {voce.alias}
            </NavLink>
          ))}

          {togglemenucat
            ? categorie.map((cat, index) => {
                if (id_formula == 1) {
                  return (
                    <NavLink
                      to={`products/${id_ordine}/${cat.id_categoria}/${cat.tipo}`}
                      style={({ isActive }) => ({
                        color: isActive ? "#fff" : "#545e6f",
                        background: isActive ? "#7600dc" : null,
                      })}
                      key={index}
                      onClick={toggle}
                    >
                      {cat.nome}
                    </NavLink>
                  );
                } else {
                  if (cat.tipo == 0) {
                    return (
                      <NavLink
                        to={`products/${id_ordine}/${cat.id_categoria}/${cat.tipo}`}
                        style={({ isActive }) => ({
                          color: isActive ? "#fff" : "#545e6f",
                          background: isActive ? "#7600dc" : null,
                        })}
                        key={index}
                        onClick={toggle}
                      >
                        {cat.nome}
                      </NavLink>
                    );
                  }
                }
              })
            : null}
          <NavLink
            to="/logout"
            style={({ isActive }) => ({
              color: isActive ? "#fff" : "#545e6f",
              background: isActive ? "#7600dc" : null,
            })}
          >
            Logout
          </NavLink>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
