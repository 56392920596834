import React, { useState } from "react";
import "../style/Modalnota.css";

const ModalNota = (props) => {
  const [note, setNote] = useState("");
  const [formData, setFormData] = useState({
    totale: 0,
    ing_scelti: [],
  });

  const [search, setNewSearch] = useState("");

  const handleSearchChange = (e) => {
    setNewSearch(e.target.value);
  };

  const filtered = !search
    ? ""
    : props.ingredienti.filter((ing) =>
        ing.nome.toLowerCase().includes(search.toLowerCase())
      );

  const handleInserisciElenco = (value) => {
    let piatto;
    piatto = props.ingredienti.find((p) => p.id_ingredienti == value);
    if (piatto) {
      var updatedList = [...formData.ing_scelti, piatto];
      setFormData({
        ...formData,
        ing_scelti: updatedList,
        totale:
          Number(formData.totale) + props.datiModal.card.prezzo != 0
            ? Number(piatto.prezzo)
            : 0,
      });
    }
    setNewSearch("");
  };
  const handleRemuveElenco = (value) => {
    var updatedList = [...formData.ing_scelti];
    let piatto;
    piatto = formData.ing_scelti.find((p) => p.id_ingredienti == value);

    updatedList.splice(
      formData.ing_scelti.findIndex((p) => p.id_ingredienti == value),
      1
    );

    setFormData({
      ...formData,
      ing_scelti: updatedList,
      totale:
        Number(formData.totale) - props.datiModal.card.prezzo != 0
          ? Number(piatto.prezzo)
          : 0,
    });
  };

  return (
    <>
      <div className="modal-container">
        <div className="container-infomodal">
          <div className="menu-close" onClick={() => props.toggleModalNota()}>
            <span className="material-symbols-rounded">close</span>
          </div>
          <div className="nome-prodotto">
            {props.datiModal.card.nome}(
            {props.datiModal.card.prezzo_offerte != 0.0 &&
            props.datiModal.card.prezzo_offerte !== undefined
              ? Number(props.datiModal.card.prezzo_offerte) + formData.totale
              : Number(props.datiModal.card.prezzo) + formData.totale}
            )
          </div>
          <div className="dati-prodotto">
            {props.ingredienti.length > 0 ? (
              <input type="text" className="campo_input" value={search} onChange={handleSearchChange} placeholder="nome dell'ingrediente"/>
            ) : null}
            <div className="conting">
              <div className="sx">
                {filtered &&
                  filtered.map((ing) => {
                    if (
                      !formData.ing_scelti.find(
                        (p) => p.id_ingredienti == ing.id_ingredienti
                      ) &&
                      ing.nome != "Base bianca con mozzarella" &&
                      ing.nome != "Base Focaccia" &&
                      ing.nome != "Base rossa con mozzarella" &&
                      ing.nome != "Piadina Classica"
                    ) {
                      return (
                        <p
                          key={ing.id_ingredienti}
                          onClick={() =>
                            handleInserisciElenco(ing.id_ingredienti)
                          }
                        >
                          {ing.nome} -{" "}
                          {props.datiModal.card.prezzo != 0 ? ing.prezzo : 0}
                        </p>
                      );
                    }
                  })}
              </div>
              <div className="ce"></div>
              <div className="dx">
              {props.ingredienti.length > 0 ? 
                <span>aggiunte</span> : null }
                {formData.ing_scelti &&
                  formData.ing_scelti.map((ing) => {
                    return (
                      <p
                        key={ing.id_ingredienti}
                        onClick={() => handleRemuveElenco(ing.id_ingredienti)}
                      >
                        {ing.nome} -{" "}
                        {props.datiModal.card.prezzo != 0 ? ing.prezzo : 0}
                      </p>
                    );
                  })}
              </div>
            </div>
            <div className="pulsanti">
              <div className="dec">
                <a
                  href="#fakeLink"
                  onClick={() => setNote(note + " SENZA: ")}
                  className="note_btn"
                >
                  Togli
                </a>
              </div>

              <div className="dec">
                <a
                  href="#fakeLink"
                  onClick={
                    props.datiModal.id_formula != 1 ||
                    props.datiModal.storico_uscite == 1
                      ? () =>
                          props.onIncrement(
                            props.datiModal.card,
                            note,
                            formData
                          )
                      : () =>
                          props.toggleModalUscite(
                            props.datiModal.card,
                            note,
                            formData
                          )
                  }
                  className="note_btn"
                >
                  Conferma
                </a>
              </div>
            </div>
            <div className="area_note">
              <span className="field__label-wrap" aria-hidden="true">
                <span className="field__label">note</span>
              </span>
              <textarea
                id="first-name"
                type="text"
                className="field__input"
                onChange={(e) => setNote(e.target.value)}
                value={note}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ModalNota;
