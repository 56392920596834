import React, { useState } from "react";
import "../style/MySelect.css";

const SelectFormule = ({
  onValue,
  value,
  formule,
  
  
}) => {
  return (
    <div className="select">
      <select
        className="select"
        aria-label="Default select example"
        value={value}
        onChange={(e) => onValue(e.target.value)}
      >
       {/*  <option value="-1">Seleziona una Formula</option> */}
        {formule &&
          formule.map((formula) => (
            <option
              key={formula.id_formula}
              value={formula.id_formula}
              selected
            >
              {formula.nome}
            </option>
          ))}
      </select>
    </div>
  );
};
export default SelectFormule;
