import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "../style/Table.css";
import MyButton from "./MyButton";

const TableOrdiniAperti = ({
  ordini,
  chiudiordine,
  ordina,
  ruolo,
  stapmaqr,
  storico,
}) => {
  let date_format;
  return (
    <table>
      <thead>
        <th>N° Ordine</th>
        <th>Data</th>
        <th>Tavolo</th>
        <th>N° Persone</th>
        <th>N° Bambini</th>
        <th>Totale(€)</th>
        <th>Action</th>
      </thead>
      <tbody>
        {ordini.map((ordine, index) => {
          date_format = new Date(ordine.createdAt);
          return (
            <tr key={index}>
              <td>
                <label>N° Ordine</label>
                <span>{ordine.id_ordine}</span>
              </td>
              <td>
                <label>data</label>
                <span>{date_format.toLocaleString()}</span>
              </td>
              <td>
                <label>Tavolo</label>
                <span>{ordine.ordini_tavolos[0].numero_tavolo}</span>
              </td>
              <td>
                <label>N° Persone</label>
                <span>{ordine.ordini_tavolos[0].nume_persone}</span>
              </td>
              <td>
                <label>N° Bambini</label>
                <span>{ordine.ordini_tavolos[0].nume_bambini}</span>
              </td>
              <td>
                <label>Totale</label>
                <span>{ordine.totale} €</span>
              </td>
              <td>
             
              <div className="contenuto">
              {ordine.persone.ruolis[0].id_ruolo!=4 ? <MyButton
                  onClick={() => stapmaqr(ordine.ordini_tavolos[0].id_ordini_tavolo)}
                  className="large-icon mybutton"
                  tooltip={"Stampa QR"}
                  name={"print"}
                />:null}
              {ruolo == 1 || ordine.stato == 1 ? null : (
                <MyButton
                  onClick={() =>
                    ordina(
                      ordine.id_ordine,
                      ordine.ordini_tavolos[0].nume_persone +
                        ordine.ordini_tavolos[0].nume_bambini,
                      ordine.totale,
                      ordine.ordini_tavolos[0].numero_tavolo,
                      ordine.ordini_tavolos[0].id_formula,
                      ordine.storico,
                      ordine.storico_uscite,
                      ordine.tipo
                    )
                  }
                  className="large-icon mybutton"
                  tooltip={"Ordina"}

                  name={"contract_edit"}
                />
                  )} 
                  {ordine.stato == 1 ? null : (
                <MyButton
                  onClick={() => chiudiordine(ordine.id_ordine, ordine.tipo)}
                  className="large-icon mybutton"
                  tooltip={"Chiudi Ordine"}
                  name={"shopping_cart_checkout"}
                />
                )}
              </div>
            </td>
              {/*  <td>
                {ruolo == 1 || ordine.stato == 1 ? null : (
                  <a
                    href="#fakeLink"
                    onClick={() =>
                      ordina(
                        ordine.id_ordine,
                        ordine.ordini_tavolos[0].nume_persone +
                          ordine.ordini_tavolos[0].nume_bambini,
                        ordine.totale,
                        ordine.ordini_tavolos[0].numero_tavolo,
                        ordine.ordini_tavolos[0].id_formula,
                        ordine.storico,
                        ordine.storico_uscite,
                        ordine.tipo
                      )
                    }
                    className="btn purple"
                  >
                    Ordina
                  </a>
                )}
                {ordine.stato == 1 ? null : (
                  <a
                    href="#fakeLink"
                    onClick={() => chiudiordine(ordine.id_ordine, ordine.tipo)}
                    className="btn purple"
                  >
                    Chiudi
                  </a>
                )}
              </td>  */}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableOrdiniAperti;
