import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  get_prodotti,
  get_categorieall,
  get_prodotto_byid,
  get_prodotto_byid_success,
  reset_prodotto,
  get_categorieutili,
  cambia_stato_prodotti,
  get_formule,
  get_all_formule,
} from "../store/actions/ordini";
import Navbar from "../components/Navbar";
import Spinner from "../components/Spinner";
import TablePortate from "../components/TablePortate";
import "../style/Portate.css";
import { loading_login } from "../store/actions/login";
import { useState } from "react";

const Portate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.ordini.loading);
  const portate = useSelector((state) => state.ordini.portate);
  const loading2 = useSelector((state) => state.login.loading);
  const id_ruolo = useSelector((state) => state.login.id_ruolo);

  const totalPage = useSelector((state) => state.ordini.total_page);
  const limit =useSelector((state) => state.ordini.limit);
  const [currentPage, setCurrentPage] = useState(1);
console.log("pagina corrente",currentPage)
  useEffect(() => {
    async function fetchportate() {
      await dispatch(loading_login(true));
      await dispatch(get_prodotti(currentPage, limit));
      await dispatch(loading_login(false));
    }
    fetchportate();
  }, [currentPage]);

  useEffect(() => {
    async function fetchportate() {
      await dispatch(get_categorieutili());
    }
    fetchportate();
  }, []);
  const modifica = async (id) => {
    await dispatch(get_all_formule());

    if (id != 0) {
      await dispatch(get_prodotto_byid(id));
      navigate(`../piatto/${id}`);
    } else {
      await dispatch(reset_prodotto());
      navigate(`../piatto/0`);
    }
  };
  const toggle = async (id, stato) => {
    
    if (stato == 0) {
      await dispatch(cambia_stato_prodotti(id, 1));
    } else {
      await dispatch(cambia_stato_prodotti(id, 0));
    }
    await dispatch(loading_login(true));
    await dispatch(get_prodotti(currentPage, limit));
    await dispatch(loading_login(false));
  };
  let itemList = [];
  const inserisciPagine = () => {
    for (let i = 1; i <= totalPage; i++) {
      if (i == currentPage) {
        itemList.push(
          <a
            key={i}
            href="#fakeLink"
            onClick={() => {
              setCurrentPage(i);
            
            }}
            className="btn_page actived"
          >
            {i}
          </a>
        );
      } else {
        itemList.push(
          <a
            key={i}
            href="#fakeLink"
            onClick={() => {
              setCurrentPage(i);
             
            }}
            className="btn_page purple"
          >
            {i}
          </a>
        );
      }
    }
    return itemList;
  };
  return (
    <div className="container-ordini margin50">
      {id_ruolo == 1 ? (
        <div className="apritavolo">
          <a
            href="#fakeLink"
            onClick={() => modifica(0)}
            className="btn purple"
          >
            Nuovo Piatto
          </a>
        </div>
      ) : null}

      {loading || loading2 ? (
        <Spinner />
      ) : portate != 0 && totalPage != -1 ? (
        <>
          <div className="contenuto-ordini">
            <TablePortate
              portate={portate}
              modifica={modifica}
              toggle={toggle}
             
            />
          </div>
          <div className="nume-page">
            {totalPage > 0 ? inserisciPagine() : null}
          </div>
        </>
      ) : (
        "Non hai ancora caricato nessun piatto"
      )}
    </div>
  );
};

export default Portate;
