import { combineReducers } from "redux";
import { DESTROY_SESSION } from "../actions/login";

import login from "./login";
import ordini from "./ordini";
import useform from "./useform";

const appReducer = combineReducers({
  login, useform, ordini
});

const rootReducer = (state, action) => {
    
  if (action.type === DESTROY_SESSION) {
    state = undefined;
  }

  return appReducer(state, action);
};


export default rootReducer;
