import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  aggiorna_dati_ordine,
  set_ordine,
  set_storico_ordine,
  togglenavcat,
} from "../store/actions/ordini";
import "../style/Ordini.css";
import Tabs from "../components/tabcomponentsordinitavolo/Tabs";

const Ordini = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ordini = useSelector((state) => state.ordini.ordini);
  const loading = useSelector((state) => state.ordini.loading);
  const loading2 = useSelector((state) => state.login.loading);
  const id_ruolo = useSelector((state) => state.login.id_ruolo);
  const id_ordine = useSelector((state) => state.ordini.id_ordine);
  useEffect(() => {
    async function reset() {
      await dispatch(set_storico_ordine(-1));

      await dispatch(set_ordine(-1));
    }

    reset();
  }, []);
 /*  useEffect(() => {
    var pusher = pusherkey;

    var channel = pusher.subscribe("react");
    channel.bind("aggiornati", function (data) {
      async function fetchattesanuoo() {
        console.log("aggiorno l'ordine in react");

        await dispatch(aggiorna_dati_ordine(data.message));
      }
      fetchattesanuoo();
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []); */
  useEffect(() => {
    async function fetchordini() {
      await dispatch(togglenavcat(false));
    }

    fetchordini();
  }, [ordini]);

  return (
    <div className="container-ordini margin50">
      {id_ruolo != 1 ? (
        <div className="apritavolo">
          <a
            href="#fakeLink"
            onClick={() => navigate("../apritavolo")}
            className="btn purple"
          >
            Apri Tavolo
          </a>
        </div>
      ) : null}

      <Tabs />
    </div>
  );
};

export default Ordini;
