import React from "react";
import "../style/Table.css";
import MyButton from "./MyButton";

const TableMenu = ({ modifica, menu, toggle }) => {
  return (
    <table>
      <thead>
        <th>Nome</th>
        <th>Piatti</th>
        <th>Prezzo (€)</th>
        <th>offerta(€)</th>
        <th>Action </th>
      </thead>
      <tbody>
        {menu[0].prodottis.map((smenu, index) => {
          let piatto = "";
          smenu.composizione_menus.map((prodotti, index) => {
            piatto =
              piatto +
              " " +
              prodotti.Prodotti_Correlati.categorie.nome +
              ": " +
              prodotti.Prodotti_Correlati.nome +
              ", ";
          });
          return (
            <tr key={index}>
              <td>
                <label>Nome</label>
                <span>{smenu.nome}</span>
              </td>
              <td>
                <label>Piatti</label>
                <span>{piatto}</span>
              </td>

              <td>
                <label>Prezzo (€)</label>
                <span>{smenu.prezzo.toFixed(2)} € </span>
              </td>
              <td>
                <label>Prezzo (€)</label>
                <span>{smenu.prezzo_offerte.toFixed(2)} €</span>
              </td>

              <td>
                <div className="contenuto">
                  <MyButton
                    onClick={() => modifica(smenu.id_prodotto)}
                    className="large-icon mybutton"
                    name={"contract_edit"}
                  tooltip={"Modifica"}

                  />

                  <MyButton
                    onClick={() => toggle(smenu.id_prodotto, smenu.stato)}
                    className="large-icon mybutton"
                    name={smenu.stato == 0 ? "visibility" : "visibility_off"}
                  tooltip={smenu.stato == 0 ? "Attiva" : "Disattiva"}

                  />
                </div>
                {/* <a
                  href="#fakeLink"
                  onClick={() => modifica(smenu.id_prodotto)}
                  className="btn purple"
                >
                  Modifica
                </a>
                <a
                  href="#fakeLink"
                  onClick={() => toggle(smenu.id_prodotto, smenu.stato)}
                  className="btn purple"
                >
                  {smenu.stato == 0 ? "Attiva" : "Disattiva"}
                </a> */}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableMenu;
