import React from "react";
import "../style/Table.css";
import MyButton from "./MyButton";

const TableIngredienti = ({
  ingredienti,
  modifica,
  toggle,
  /*  totalPage,
  currentPage,
  setCurrentPage,
  setOffset,
  limit, */
}) => {
  return (
    <table>
      <thead>
        <th>Categorie</th>
        <th>Nome</th>
        <th>Prezzo(€)</th>
        <th>Action</th>
      </thead>
      <tbody>
        {ingredienti.map((ingrediente, index) => {
          return (
            <tr key={index}>
              <td>
                <label>Categorie</label>
                <span>{ingrediente.categorie_ingredienti.nome}</span>
              </td>
              <td>
                <label>Nome</label>
                <span>{ingrediente.nome}</span>
              </td>

              <td>
                <label>Prezzo(€)</label>
                <span>{ingrediente.prezzo.toFixed(2)} €</span>
              </td>

              <td>
                <div className="contenuto">
                  <MyButton
                    onClick={() => modifica(ingrediente.id_ingredienti)}
                    className="large-icon mybutton"
                    name={"contract_edit"}
                  tooltip={"Modifica"}

                  />

                  <MyButton
                    onClick={() =>
                      toggle(ingrediente.id_ingredienti, ingrediente.stato)
                    }
                    className="large-icon mybutton"
                    name={
                      ingrediente.stato == 0 ? "visibility" : "visibility_off"
                    }
                  tooltip={ingrediente.stato == 0 ? "Attiva" : "Disattiva"}
                    
                  />
                </div>
                {/*  <a
                  href="#fakeLink"
                  onClick={() => modifica(ingrediente.id_ingredienti)}
                  className="btn purple"
                >
                  Modifica
                </a>
                <a
                  href="#fakeLink"
                  onClick={() =>
                    toggle(ingrediente.id_ingredienti, ingrediente.stato)
                  }
                  className="btn purple"
                >
                  {ingrediente.stato == 0 ? "Attiva" : "Disattiva"}
                </a> */}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableIngredienti;
