import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  aggiorna_dati_ordine,
  set_formula,
  set_ordine,
  set_storico_ordine,
  set_tipo,
  togglenavcat,
} from "../store/actions/ordini";
import "../style/Ordini.css";
import Tabs from "../components/tabcomponentsombrelloni/Tabs";

const Ordini_ombrelloni = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ordini = useSelector((state) => state.ordini.ordini);
  const loading = useSelector((state) => state.ordini.loading);
  const loading2 = useSelector((state) => state.login.loading);
  const id_ruolo = useSelector((state) => state.login.id_ruolo);
  const id_ordine = useSelector((state) => state.ordini.id_ordine);
  useEffect(() => {
    async function reset() {
      await dispatch(set_storico_ordine(-1));

      await dispatch(set_ordine(-1));
      await dispatch(set_tipo(-1));
      await dispatch(set_formula(-1));
    }

    reset();
  }, []);

  useEffect(() => {
    async function fetchordini() {
      await dispatch(togglenavcat(false));
    }

    fetchordini();
  }, [ordini]);

  return (
    <div className="container-ordini margin50">
      {id_ruolo == 1 ? (
        <div className="apritavolo">
          <a
            href="#fakeLink"
            onClick={() => navigate("../assegnaombrellone")}
            className="btn purple"
          >
            Assegna Ombrellone
          </a>
        </div>
      ) : null}

      <Tabs />
    </div>
  );
};

export default Ordini_ombrelloni;
