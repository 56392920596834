import React, { useState } from "react";
import "../style/MySelect.css";

const SelectCategorieIngredienti = ({
  formData,
  setFormData,
  categorieall,
}) => {
  const [value, setValue] = useState("");
  const handleValue = (e) => {
    setValue(e.target.value);
  };

  return (
    
        <select
          className="select"
          aria-label="Default select example"
          onChange={(e) =>
            setFormData({
              ...formData,
              id_categorie_ingredienti: e.target.value,
            })
          }
          value={formData.id_categorie_ingredienti}
        >
          <option value="-1">-- Seleziona una categoria --</option>
          {categorieall.map((categoria) => (
            <option
              key={categoria.id_categorie_ingredienti}
              value={categoria.id_categorie_ingredienti}
            >
              {categoria.nome}
            </option>
          ))}
        </select>
 
  );
};
export default SelectCategorieIngredienti;
