import axios from 'axios'


const node =axios.create({
   
   // baseURL :"https://menu.businesssolution.cloud:8090"
    //baseURL : variabili.variabili.baseURL
    baseURL :""
})

export {node}

