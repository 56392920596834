import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import '../style/Form.css';
import FormLogin from './FormLogin';
import FormSuccess from './FormSuccess';
import Spinner from './Spinner';

const Form = ({login,getMenu}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const loading = useSelector((state) => state.login.loading);

  function submitForm() {
    setIsSubmitted(true);
  }
 

  return (
    <>
      <div className='form-container'>
        {!isSubmitted ? (
       
           loading ? <Spinner/> :
          <FormLogin submitForm={submitForm} login={login}/>
        ) : (
          loading ? <Spinner/> :
          <FormSuccess getMenu={getMenu}/>
        )}
        
      </div>
    </>
  );
};

export default Form;
