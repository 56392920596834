import {
  CARRELLO_SUCCESS,
  CARRELLO_START,
  CARRELLO_FAIL,
  AGGIUNGI_PRODOTTO_CART,
  INCREMENTA_PRODOTTO,
  DECREMENTA_PRODOTTO,
  RECUPERA_CARRELLO,
  SET_COPERTI,
  GET_PRODOTTIBYCATEGORIA_START,
  GET_PRODOTTIBYCATEGORIA_SUCCESS,
  GET_PRODOTTIBYCATEGORIA_FAIL,
  SEND_ORDINE_START,
  SEND_ORDINE_SUCCESS,
  SEND_ORDINE_FAIL,
  SET_TOTALE_GENERALE,
  SET_TIPO,
  SET_ORDINE,
  AZZERA_PRODOTTI,
  GET_ORDINI_START,
  GET_ORDINI_SUCCESS,
  GET_ORDINI_FAIL,
  ISCARRELLO,
  GET_INGREDIENTI_START,
  GET_INGREDIENTI_SUCCESS,
  GET_INGREDIENTI_FAIL,
  GET_OFFERTE_START,
  GET_OFFERTE_SUCCESS,
  GET_OFFERTE_FAIL,
  GET_STORICO_ORDINi_START,
  GET_STORICO_ORDINi_SUCCESS,
  GET_STORICO_ORDINi_FAIL,
  SET_STORICO_ORDINE,
  GET_PRODOTTI_START,
  GET_PRODOTTI_SUCCESS,
  GET_PRODOTTI_FAIL,
  GET_CATEGORIEALL_START,
  GET_CATEGORIEALL_SUCCESS,
  GET_CATEGORIEALL_FAIL,
  SALVA_PRODOTTO_START,
  SALVA_PRODOTTO_SUCCESS,
  SALVA_PRODOTTO_FAIL,
  GET_PRODOTTO_BYID_START,
  GET_PRODOTTO_BYID_SUCCESS,
  GET_PRODOTTO_BYID_FAIL,
  MODIFICA_PRODOTTO_START,
  MODIFICA_PRODOTTO_SUCCESS,
  MODIFICA_PRODOTTO_FAIL,
  RESET_PRODOTTO,
  GET_INGREDIENTEBYID_START,
  GET_INGREDIENTEBYID_SUCCESS,
  GET_INGREDIENTEBYID_FAIL,
  RESET_INGREDIENTE,
  RESET_OMBRELLONE,
  GET_CATEGORIE_INGREDIENTI_START,
  GET_CATEGORIE_INGREDIENTI_SUCCESS,
  GET_CATEGORIE_INGREDIENTI_FAIL,
  SALVA_INGREDIENTE_START,
  SALVA_INGREDIENTE_SUCCESS,
  SALVA_INGREDIENTE_FAIL,
  MODIFICA_INGREDIENTE_START,
  MODIFICA_INGREDIENTE_SUCCESS,
  MODIFICA_INGREDIENTE_FAIL,
  SALVA_MENU_START,
  SALVA_MENU_SUCCESS,
  SALVA_MENU_FAIL,
  GET_MENU_START,
  GET_MENU_SUCCESS,
  GET_MENU_FAIL,
  RESET_PRODOTTI,
  SET_TAVOLO,
  SET_MENU,
  SET_MENU_CAT,
  SET_IDFORMULA,
  GET_FORMULE_START,
  GET_FORMULE_FAIL,
  GET_FORMULE_SUCCESS,
  GET_TARIFFE_SUCCESS,
  AGG_DATI_ORDINE,
  SET_STORICO_USCITE_ORDINE,
  GET_OMBRELLONI_START,
  GET_OMBRELLONI_SUCCESS,
  GET_OMBRELLONI_FAIL,
  GET_OMBRELLONIBYID_START,
  GET_OMBRELLONIBYID_SUCCESS,
  GET_OMBRELLONIBYID_FAIL,
  GET_OMBRELLONI_DISPONIBILI_SUCCESS,
  GET_ORDINI_OMBRELLONI_SUCCESS,
  AGG_DATI_ORDINE_OMBRELLONE,
  SET_ERROR,
  GET_ALL_PRODOTTI_FAIL,
  GET_ALL_PRODOTTI_SUCCESS,
  GET_ALL_PRODOTTI_START,
} from "../actions/ordini";

const initialState = {
  prodotti: [],
  limit: 20,
  offerte: [],
  prodotto: [],
  portate: [],
  categorie: [],
  listamenu: [],
  tavolo: "",
  categorie_ingredienti: [],
  categoreall: [],
  carrello: [],
  ordini: [],
  formule: [],
  ingredienti: [],
  ingredienti: [],
  ombrelloni: [],
  ombrellone: [],
  ordini_ombrelloni: [],
  tariffe: [],
  qty_prodotti: 0,
  qty_bibite: 0,
  tot_prezzo: 0.0,
  tot_generale: 0.0,
  coperti: 0,
  storico: 0,
  storico_uscite: "",
  id_ordine: -1,
  total_page: -1,
  count_ingredienti: 0,
  count_portate: 0,
  count_menu: 0,
  count: 0,
  storico_ordini: [],
  loading: false,
  iscarrello: false,
  error: false,
  errortext: "",
  togglemenu: true,
  togglemenucat: false,
  id_formula: "",
  tipo: "",
};

function ordini(state = initialState, action) {
  switch (action.type) {
    case SET_STORICO_ORDINE:
      console.log("setto storico");
      return {
        ...state,
        storico: action.value,
      };
    case SET_STORICO_USCITE_ORDINE:
      console.log("setto storico");
      return {
        ...state,
        storico_uscite: action.value,
      };
    case RESET_PRODOTTO:
      return {
        ...state,
        prodotto: [],
      };
    case RESET_PRODOTTI:
      return {
        ...state,
        prodotti: [],
      };
    case GET_PRODOTTO_BYID_START:
      return {
        ...state,
        loading: true,
      };

    case GET_PRODOTTO_BYID_SUCCESS:
      return {
        ...state,
        prodotto: action.res.data.prodotti,
        loading: false,
        error: false,
      };
    case GET_PRODOTTO_BYID_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_FORMULE_START:
      return {
        ...state,
        loading: true,
      };
    case GET_FORMULE_SUCCESS:
      return {
        ...state,
        formule: action.res.data.formule,
        loading: false,
        error: false,
      };
    case GET_TARIFFE_SUCCESS:
      return {
        ...state,
        tariffe: action.res.data.tariffe,
        loading: false,
        error: false,
      };
    case GET_FORMULE_FAIL:
      return {
        ...state,
        error: true,
      };
    case SALVA_PRODOTTO_START:
      return {
        ...state,
        loading: true,
      };
    case SALVA_PRODOTTO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case SALVA_PRODOTTO_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SALVA_MENU_START:
      return {
        ...state,
        loading: true,
      };
    case SALVA_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case SALVA_MENU_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case MODIFICA_PRODOTTO_START:
      return {
        ...state,
        loading: true,
      };
    case MODIFICA_PRODOTTO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case MODIFICA_PRODOTTO_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case SALVA_INGREDIENTE_START:
      return {
        ...state,
        loading: true,
      };
    case SALVA_INGREDIENTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case SALVA_INGREDIENTE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case MODIFICA_INGREDIENTE_START:
      return {
        ...state,
        loading: true,
      };
    case MODIFICA_INGREDIENTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case MODIFICA_INGREDIENTE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_STORICO_ORDINi_START:
      return {
        ...state,
        loading: true,
      };
    case GET_STORICO_ORDINi_SUCCESS:
      return {
        ...state,
        storico_ordini: action.res.data.storico,
        loading: false,
        error: false,
      };
    case GET_STORICO_ORDINi_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_OFFERTE_START:
      return {
        ...state,
        loading: true,
      };
    case GET_OFFERTE_SUCCESS:
      return {
        ...state,
        prodotti: action.res.data.prodotti,
        loading: false,
        error: false,
      };
    case GET_OFFERTE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case RESET_INGREDIENTE:
      return {
        ...state,
        ingrediente: [],
      };
    case RESET_OMBRELLONE:
      return {
        ...state,
        ombrellone: [],
      };
    case GET_INGREDIENTI_START:
      return {
        ...state,
        loading: true,
      };
    case GET_INGREDIENTI_SUCCESS:
      return {
        ...state,
        ingredienti: action.res.data.ingredienti,

        total_page: action.res.data.totalPages,
        loading: false,
        error: false,
      };
    case GET_INGREDIENTI_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_OMBRELLONI_DISPONIBILI_SUCCESS:
      return {
        ...state,
        ombrelloni: action.res.data.ombrelloni,

        loading: false,
        error: false,
      };

    case GET_OMBRELLONI_START:
      return {
        ...state,
        loading: true,
      };
    case GET_OMBRELLONI_SUCCESS:
      return {
        ...state,
        ombrelloni: action.res.data.ombrelloni,

        total_page: action.res.data.totalPages,
        loading: false,
        error: false,
      };
    case GET_OMBRELLONI_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_INGREDIENTEBYID_START:
      return {
        ...state,
        loading: true,
      };
    case GET_INGREDIENTEBYID_SUCCESS:
      return {
        ...state,
        ingrediente: action.res.data.ingrediente,
        loading: false,
        error: false,
      };
    case GET_INGREDIENTEBYID_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_OMBRELLONIBYID_START:
      return {
        ...state,
        loading: true,
      };
    case GET_OMBRELLONIBYID_SUCCESS:
      return {
        ...state,
        ombrellone: action.res.data.ombrellone,
        loading: false,
        error: false,
      };
    case GET_OMBRELLONIBYID_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_CATEGORIE_INGREDIENTI_START:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_CATEGORIE_INGREDIENTI_SUCCESS:
      return {
        ...state,
        categorie_ingredienti: action.res.data.categorie,
        loading: false,
      };
    case GET_CATEGORIE_INGREDIENTI_FAIL:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case GET_PRODOTTIBYCATEGORIA_START:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODOTTIBYCATEGORIA_SUCCESS:
      return {
        ...state,
        prodotti: action.res.data.prodotti[0].prodottis,

        loading: false,
        error: false,
      };
    case GET_PRODOTTI_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_PRODOTTI_START:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODOTTI_SUCCESS:
      return {
        ...state,
        portate: action.res.data.prodotti,
        total_page: action.res.data.totalPages,

        loading: false,
        error: false,
      };

    case GET_ALL_PRODOTTI_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_ALL_PRODOTTI_START:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_PRODOTTI_SUCCESS:
      return {
        ...state,
        portate: action.res.data.prodotti,

        loading: false,
        error: false,
      };

    case GET_MENU_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_MENU_START:
      return {
        ...state,
        loading: true,
      };
    case GET_MENU_SUCCESS:
      return {
        ...state,
        listamenu: action.res.data.menu,
        total_page: action.res.data.totalPages,

        loading: false,
        error: false,
      };

    case GET_CATEGORIEALL_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_CATEGORIEALL_START:
      return {
        ...state,
        loading: true,
      };

    case GET_CATEGORIEALL_SUCCESS:
      return {
        ...state,
        categorieall: action.res.data.categorie,

        loading: false,
        error: false,
      };
    case GET_PRODOTTIBYCATEGORIA_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_ORDINI_START:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDINI_SUCCESS:
      return {
        ...state,
        ordini: action.res.data.ordini,
        loading: false,
        error: false,
      };
    case GET_ORDINI_OMBRELLONI_SUCCESS:
      return {
        ...state,
        ordini_ombrelloni: action.res.data.ordini,
        loading: false,
        error: false,
      };
    case GET_ORDINI_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case AZZERA_PRODOTTI:
      return {
        ...state,
        prodotti: [],
      };
    case CARRELLO_START:
      return {
        ...state,
        loading: true,
      };
    case CARRELLO_SUCCESS:
      return {
        ...state,
        carrello: action.res.data.carrello,
        loading: false,
        error: false,
      };
    case CARRELLO_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SEND_ORDINE_START:
      return {
        ...state,
        loading: true,
      };
    case SEND_ORDINE_SUCCESS:
      return {
        ...state,
        carrello: [],
        qty_prodotti: 0.0,
        qty_bibite: 0,
        tot_prezzo: 0.0,
        loading: false,
        error: false,
      };
    case SEND_ORDINE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SET_TOTALE_GENERALE:
      return {
        ...state,
        tot_generale: action.tot_generale,
      };
    case SET_TIPO:
      return {
        ...state,
        tipo: action.tipo,
      };
    case SET_ORDINE:
      return {
        ...state,
        id_ordine: action.id_ordine,
      };

    case DECREMENTA_PRODOTTO:
      let prodotto2 = state.carrello.find(
        (p) => p.id_prodotto_interno === action.id_prodotto_interno
      );

      if (prodotto2.quantita == 1) {
        console.log("quantita ", prodotto2.quantita);
        return {
          ...state,
          carrello: [...state.carrello.filter((p) => p !== prodotto2)],
          qty_prodotti: state.qty_prodotti - 1,
          qty_bibite:
            prodotto2.categoria == 1 && prodotto2.prezzo == 0
              ? state.qty_bibite - 1
              : state.qty_bibite,
          tot_prezzo: state.tot_prezzo - prodotto2.prezzo,
        };
      } else {
        return {
          ...state,
          carrello: state.carrello.map((content, i) =>
            content.id_prodotto_interno === action.id_prodotto_interno
              ? { ...content, quantita: content.quantita - 1 }
              : content
          ),
          qty_prodotti: state.qty_prodotti - 1,
          qty_bibite:
            prodotto2.categoria == 1 && prodotto2.prezzo == 0
              ? state.qty_bibite - 1
              : state.qty_bibite,
          tot_prezzo: state.tot_prezzo - prodotto2.prezzo,
        };
      }
    case AGGIUNGI_PRODOTTO_CART:
      if (action.card.special == 0) {
        let prodotto = state.carrello.find(
          (p) =>
            p.id_prodotto === action.card.id_prodotto &&
            p.special == 0 &&
            p.uscita === action.card.uscita
        );
        if (prodotto) {
          console.log("uscita uguale");
          return {
            ...state,
            carrello: [
              ...state.carrello.filter((p) => p !== prodotto),
              {
                ...prodotto,
                quantita: prodotto.quantita + 1 * action.card.quantita,
              },
            ],
            qty_prodotti: state.qty_prodotti + 1 * action.card.quantita,
            qty_bibite:
              action.card.categoria == 1 && action.card.prezzo == 0
                ? state.qty_bibite + 1 * action.card.quantita
                : state.qty_bibite,
            tot_prezzo:
              state.tot_prezzo + action.card.prezzo * action.card.quantita,
          };
        } else {
          console.log("uscita non uguale");

          return {
            ...state,
            carrello: [...state.carrello, action.card],
            qty_prodotti: state.qty_prodotti + 1 * action.card.quantita,
            qty_bibite:
              action.card.categoria == 1 && action.card.prezzo == 0
                ? state.qty_bibite + 1 * action.card.quantita
                : state.qty_bibite,
            tot_prezzo:
              state.tot_prezzo + action.card.prezzo * action.card.quantita,
          };
        }
      } else if (action.card.special == 1) {
        return {
          ...state,
          carrello: [...state.carrello, action.card],
          qty_prodotti: state.qty_prodotti + 1 * action.card.quantita,
          qty_bibite:
            action.card.categoria == 1 && action.card.prezzo == 0
              ? state.qty_bibite + 1 * action.card.quantita
              : state.qty_bibite,
          tot_prezzo:
            state.tot_prezzo + action.card.prezzo * action.card.quantita,
        };
      } else {
        return {
          ...state,
          carrello: [...state.carrello, action.card],
          qty_prodotti: state.qty_prodotti + 1 * action.card.quantita,
          qty_bibite:
            action.card.categoria == 1 && action.card.prezzo == 0
              ? state.qty_bibite + 1 * action.card.quantita
              : state.qty_bibite,
          tot_prezzo:
            state.tot_prezzo + action.card.prezzo * action.card.quantita,
        };
      }

    case INCREMENTA_PRODOTTO:
      console.log("incremento il prodotto: ", action.id_prodotto_interno);
      let prodotto = state.carrello.find(
        (p) => p.id_prodotto_interno === action.id_prodotto_interno
      );
      return {
        ...state,
        carrello: state.carrello.map((content, i) =>
          content.id_prodotto_interno === action.id_prodotto_interno
            ? { ...content, quantita: content.quantita + 1 }
            : content
        ),
        qty_prodotti: state.qty_prodotti + 1,
        qty_bibite:
          prodotto.categoria == 1 && prodotto.prezzo == 0
            ? state.qty_bibite + 1
            : state.qty_bibite,

        tot_prezzo: state.tot_prezzo + prodotto.prezzo,
      };
    case AGG_DATI_ORDINE:
      return {
        ...state,
        ordini: state.ordini.map((content, i) =>
          content.id_ordine == action.ordine.id_ordine
            ? {
                ...content,
                totale: parseFloat(action.ordine.totale),
                storico: action.ordine.storico,
              }
            : content
        ),
        storico: -1,
        id_ordine: -1,
      };
    case AGG_DATI_ORDINE_OMBRELLONE:
      return {
        ...state,
        ordini_ombrelloni: state.ordini_ombrelloni.map((content, i) =>
          content.id_ordine == action.ordine.id_ordine
            ? {
                ...content,
                totale: parseFloat(action.ordine.totale),
                storico: action.ordine.storico,
              }
            : content
        ),
        storico: -1,
        id_ordine: -1,
      };
    case RECUPERA_CARRELLO:
      return {
        ...state,
        carrello: [...action.carrello],
        qty_prodotti: action.qty,
        tot_prezzo: action.totale,
      };

    case SET_COPERTI:
      return {
        ...state,
        coperti: action.coperti,
      };
    case ISCARRELLO:
      return {
        ...state,
        iscarrello: action.value,
      };
    case SET_TAVOLO:
      return {
        ...state,
        tavolo: action.value,
      };
    case SET_MENU:
      return {
        ...state,
        togglemenu: action.value,
      };
    case SET_MENU_CAT:
      return {
        ...state,
        togglemenucat: action.value,
      };
    case SET_IDFORMULA:
      return {
        ...state,
        id_formula: action.value,
      };
    case SET_ERROR:
      return {
        ...state,
        error: true,
        errortext: action.value,
      };

    default:
      return state;
  }
}

export default ordini;
