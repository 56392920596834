import React, { useState } from "react";
import "../style/MySelect.css";

const SelectStato = ({ formData, setFormData, stato }) => {
  const [value, setValue] = useState("");
  const handleValue = (e) => {
    setValue(e.target.value);
  };

  return (
    <select
      className="select"
      aria-label="Default select example"
      onChange={(e) => setFormData({ ...formData, stato: e.target.value })}
      value={formData.stato}
    >
      <option value="-1">-- Seleziona lo Stato --</option>
      {stato.map((s) => (
        <option key={s.id} value={s.valore}>
          {s.nome}
        </option>
      ))}
    </select>
  );
};
export default SelectStato;
