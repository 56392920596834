import React from "react";
import "../style/MyButton.css";

const MyButton = ({ onClick, name, className,tooltip }) => {
  return (
    <div className="mybutton" onClick={onClick}>
      <span
        className={`material-symbols-outlined ${className}`}
        title={tooltip}
      >
        {name}
      </span>
    </div>
  );
};

export default MyButton;
