import React from "react";
import "../style/BaseInfo.css";

const SpecialiInfo = (props) => {
  const checkList = props.ingredienti;

  const handleCheck = async (event) => {
    let ing = props.ingredienti.find(
      (p) => p.id_ingredienti == event.target.value
    );
    var updatedList = [...props.formData.special];
    if (event.target.checked) {
      updatedList = [
        ...props.formData.special,
        props.ingredienti.find((p) => p.id_ingredienti == event.target.value),
      ];
      props.setFormData({
        ...props.formData,
        special: updatedList,
        totale: Number(props.formData.totale) + Number(ing.prezzo),
      });
    } else {
      updatedList.splice(
        props.formData.special.findIndex(
          (p) => p.id_ingredienti == event.target.value
        ),
        1
      );
      props.setFormData({
        ...props.formData,
        special: updatedList,
        totale: Number(props.formData.totale) - Number(ing.prezzo),

      });
    }
  };

  return (
    <div className="container-fuorinfo">
      <div className="container-ing">
        {checkList.map((item, index) => {
          if (item.categorie_ingredienti.nome == "Special") {
            return (
              <label className="ingr" key={index}>
                <input
                  value={item.id_ingredienti}
                  type="checkbox"
                  onChange={handleCheck}
                  checked={
                    !props.formData.special.find(
                      (p) => p.id_ingredienti == item.id_ingredienti
                    )
                      ? false
                      : true
                  }
                />
                {item.nome} ({item.prezzo} €)
              </label>
            );
          }
        })}
      </div>
     
      <div className="spazio_input">
        <span className="campo_label" aria-hidden="true">
          <span className="field__label">Note</span>
        </span>
        <textarea
          id="first-name"
          type="text"
          className="campo_input"
          placeholder="Inserisci una nota"
          onChange={(e) =>
            props.setFormData({ ...props.formData, nota: e.target.value })
          }
          value={props.formData.nota}
        />
      </div>
    </div>
  );
};
export default SpecialiInfo;
