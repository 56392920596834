import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import "./style/App.css";

import Products from "./pages/Products";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import PrivateRoutes from "./components/PrivateRoutes";
import Layout from "./components/Layout";
import ApriTavolo from "./pages/ApriTavolo";
import NotFound from "./components/NotFound";
import Portate from "./pages/Portate";
import Ordini from "./pages/Ordini";
import Ordini_ombrelloni from "./pages/Ordini_ombrelloni";
import Storico from "./pages/Storico";
import PizzaStepper from "./pages/PizzaStepper";
import HamburgerStepper from "./pages/HamburgerStepper";
import Home from "./pages/Home";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  loading_login,
  disconnectMQTT,
  logincheck,
  connectMqtt,
} from "./store/actions/login";
import PersistLogin from "./components/PersistLogin";
import PersistLogin2 from "./components/PersistLogin2";
import Carrello from "./pages/Carrello";
import Piatti from "./pages/Piatti";
import Ingredienti from "./pages/Ingredienti";
import Ingrediente from "./pages/Ingrediente";
import Menu from "./pages/Menu";
import MenuStepper from "./pages/MenuStepper";
import LogoutMassivo from "./pages/LogoutMassivo";
import PiadinaStepper from "./pages/PiadinaStepper";
import FiftyStepper from "./pages/FiftyStepper";
import Qrmenu from "./pages/Qrmenu";
import {
  aggiorna_dati_ordine,
  get_ordini_aperti_sala,
} from "./store/actions/ordini";
import mqtt from "mqtt-browser";
import Ombrelloni from "./pages/Ombrelloni";
import Ombrellone from "./pages/Ombrellone";
import Assegna_Ombrellone from "./pages/Assegna_Ombrellone";

function App() {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.login.token);
  const sessione = JSON.parse(localStorage.getItem("SESSIONE"));
  const topics = useSelector((state) => state.login.topics);
  const mqttOptions = useSelector((state) => state.login.mqttOptions);
  const client = useSelector((state) => state.login.client);

  const isLogin = useSelector((state) => state.login.isLogin);
  const id_ruolo = useSelector((state) => state.login.id_ruolo);

  useEffect(() => {
   
    const fetchLogin = async () => {
      console.log("recupero i parametri");
      await dispatch(logincheck(sessione));
    };

    if (sessione && sessione.token !== "" && token === "") {
      fetchLogin();
    }
  }, [dispatch, sessione, token]);

  useEffect(() => {
    console.log("provo a connettere mqtt");
    const fetchConnectMQTT = async () => {
      try {
        await dispatch(connectMqtt());
      } catch (error) {
        console.log("Errore durante il recupero dei dati:", error);
      }
    };

    if (isLogin && mqttOptions && topics && !client) {
      fetchConnectMQTT();
    }
  }, [isLogin, topics, mqttOptions, client, dispatch]);


  
  return (
    <div className="App">
      <Router>
        <Routes>
          {
            <Route path="/" element={<Layout isLogin={isLogin} />}>
              <Route path="login" element={<Login isLogin={isLogin} />} />
              <Route path="*" element={<NotFound />} />

              <Route element={<PrivateRoutes isLogin={isLogin} />}>
                <Route element={<Home />} path="/" />
                <Route element={<Storico />} path="/storicoclose" exact />

                <Route element={<Ordini />} path="/ordini" />
                <Route element={<Ordini_ombrelloni />} path="/ordini_ombrelloni" />

                <Route element={<PersistLogin id_ruolo={id_ruolo} />}>
                  <Route element={<Carrello />} path="/carrello" />
                  <Route
                    element={<Products />}
                    path="/products/:ordine/:id/:flag"
                  />
                  <Route element={<ApriTavolo />} path="/apritavolo" />
                  <Route element={<Storico />} path="/storico" exact />
                  <Route
                    element={<PizzaStepper />}
                    path="/pizzastepper/:id/:uui"
                    exact
                  />
                  <Route
                    element={<PiadinaStepper />}
                    path="/piadinastepper/:id/:uui"
                    exact
                  />
                  <Route
                    element={<HamburgerStepper />}
                    path="/hamburgerstepper/:id/:uui"
                    exact
                  />
                   <Route
                    element={<FiftyStepper />}
                    path="/fiftyStepper/:id/:uui"
                    exact
                  />
                </Route>
                <Route element={<PersistLogin2 id_ruolo={id_ruolo} />}>
                  <Route element={<Portate />} path="/portate" />
                  <Route element={<Piatti />} path="/piatto/:id" />
                  <Route element={<Ingredienti />} path="/ingredienti" />
                  <Route element={<Ingrediente />} path="/ingrediente/:id" />
                  <Route element={<Ombrelloni />} path="/ombrelloni" />
                  <Route element={<Ombrellone />} path="/ombrellone/:id" />
                  <Route element={<Menu />} path="/menu" />
                  <Route element={<MenuStepper />} path="/menustepper/:id" />
                  <Route element={<Qrmenu />} path="/qrmenu" />
                  <Route element={<Assegna_Ombrellone />} path="/assegnaombrellone" />

                  

                </Route>
                <Route element={<Logout />} path="/logout" />
                <Route element={<LogoutMassivo />} path="/logoutmassivo" />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
          }
        </Routes>
      </Router>
    </div>
  ); // JSX del componente App
}

export default connect(null, { logincheck, disconnectMQTT })(App);
