import React from "react";
import "../style/Table.css";

const TableCarrello = ({ carrello, onIncrement, onDecrement }) => {
  return (
    <div className="contenuto">
      {carrello && carrello.length === 0 ? (
        <div className="contenuto-vuoto">Carrello Vuoto</div>
      ) : (
        <table>
          <thead>
            <th>Uscita</th>
            <th>Nome</th>
            <th>Note</th>
            <th>Prezzo Singolo (€)</th>
            <th>Azioni</th>
          </thead>
          <tbody>
            {carrello &&
              carrello
                .sort((a, b) => a.uscita - b.uscita)
                .map((item, index) => {
                  let color = index % 2 > 0 ? "white" : "aliceblue";
                  return (
                    <tr key={index}>
                      <td>
                        <label>Uscita</label>
                        <span>
                          {item.uscita == 0
                            ? "Subito"
                            : item.uscita == 1
                            ? "Uscita 1"
                            : item.uscita == 2
                            ? "Uscita 2"
                            : item.uscita == 3
                            ? "Uscita 3"
                            : "Uscita 4"}
                        </span>
                      </td>
                      <td>
                        <label>Nome</label>
                        <span>{item.nome}</span>
                      </td>
                      <td>
                        <label>Note</label>
                        <span>
                        {item.special ==2  || item.special ==3  ? item.ingredienti_it+" "+item.note : item.note}
                        </span>
                      </td>
                      <td>
                        <label>Prezzo Singolo (€)</label>
                        <span>{item.prezzo.toFixed(2)} €</span>
                      </td>

                      <td>
                        <div className="func_container">
                          <div className="dec">
                            <a
                              href="#fakeLink"
                              onClick={() =>
                                onDecrement(item.id_prodotto_interno)
                              }
                              className="btn circle"
                            >
                              -
                            </a>
                          </div>
                          <div className="qty">{item.quantita}</div>

                          <div className="inc">
                            <a
                              href="#fakeLink"
                              onClick={() =>
                                onIncrement(item.id_prodotto_interno)
                              }
                              className="btn circle"
                            >
                              +
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      )}
    </div>
  );
};
export default TableCarrello;
